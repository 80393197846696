import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from "react-router-dom";
import ForgetPassword from "../pages/forgotpassword";
import Login from "../pages/Login";
import ResetPassword from "../pages/reset-password";
import Channelactionscomponent from "../pages/channel-actions";
import Viewership from "../pages/viewership";
import Scheduling from "../pages/Scheduling";
import ScheduleDetail from "../pages/Scheduling/schedule-detail";
import ScheduleLoop from "../pages/Scheduling/schedule-loop";
import SchedulingLoopNew from "../pages/Scheduling/scheduling-loop/scheduling-loop-new";
import Monitor from "../pages/Scheduling/Monitor";
import CmsLayout from "../Layout/CmsLayout";
import SuperAdminLayout from "../Layout/SuperAdminLayout";
import AssetManagement from "../pages/asset-management/index";

import EditableGrid from "../pages/editable-grid";
import MasterSettings from "../pages/master-settings/MasterSettings";
import CmsChannelDetail from "../pages/asset-management/channeldetail/CmsChannelDetail";
import ChannelCreation from "../pages/channel-creation/channelCreation";
import ChannelSetup from "../pages/channel-setup/channelSetup";
import Layout from "../Layout/Layout";
import StoreFront from "../pages/StoreFront";
// import ViewershipComponent from './ViewershipComponent';
// import DashboardComponent from './DashboardComponent';
// import DashbordChannelComponent from './DashbordChannelComponent';
// import ChannelActionsComponent from './ChannelActionsComponent';
// import SchedulingComponent from './SchedulingComponent';
// import ChannelSetupComponent from './ChannelSetupComponent';
// import ChannelCreationComponent from './ChannelCreationComponent';
// import ChannelUploadComponent from './ChannelUploadComponent';
import TenantOnboarding from "../pages/tenant-onboarding/tenant-onboarding";
import SuperTenantAdd from "../pages/tenant-onboarding/super-tenant-add";
import TenantAdd from "../pages/tenant-onboarding/tenant-add";
import UserManagement from "../pages/user-management";
import UserAdd from "../pages/user-management/user-add";
import AuditLogs from "../pages/audit-logs/audit-logs";
import GridMonitor from "../pages/Scheduling/GridMonitor";
import GridMonitorView from "../Layout/GridMoitorViewLayout";
import Contact from "../pages/contact";
import ContactUs from "../pages/contact";
import PlaylistsMain from "../pages/Playlists/PlaylistsMain";
// import MonitorGridView from "../pages/girdMonitorView/monitoringGridView";
// import GridMoitorView from "../Layout/GridMoitorViewLayout";
import SchedulingLinear from "../pages/Scheduling/scheduling-linear-new/scheduling-linear";
import AddDistributionPlatform from "../component/AddDistributionPlatform";

const PrivateRoute = ({ children }) => {
  const tenantLocalData = localStorage.getItem('tenantInfo');
  const tenantLocalDataParse = JSON.parse(tenantLocalData);
  return (tenantLocalDataParse?.identifier === "cloudtv") ?  <Navigate to="/" /> :children;
};

function AppRoutes() {
  const[superAdmin, setSuperAdmin] = useState("");
  const [identifier, setIdentifier] = useState("");
  useEffect(()=>{
    const tenantLocalData = localStorage.getItem('tenantInfo');
    const tenantLocalDataParse = JSON.parse(tenantLocalData);
    setSuperAdmin(tenantLocalDataParse?.identifier);
    setIdentifier(tenantLocalDataParse?.code);
  },[superAdmin])

  const NotFoundRedirect = () => {
    const navigate = useNavigate();
    useEffect(() => {
      // Redirect to the desired path with the state
      navigate('/');
    }, [navigate]);
  };

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/contactUs" element={<ContactUs />} />
       

        {
          superAdmin === "default"?
          <Route element={<SuperAdminLayout />}>
            <Route path="/" element={<UserManagement />} />
            <Route path="/user-management" element={<UserManagement />} />
            <Route path="/tenant-onboarding" element={<TenantOnboarding />} />
            <Route path="/user-add" element={<UserAdd />} />
            <Route path="/add-super-tenant" element={<SuperTenantAdd />} />
            <Route path="/add-tenant" element={<TenantAdd />} />
            <Route path="*" element={<NotFoundRedirect />} />
            <Route path="/audit-logs" element={<AuditLogs />} />
          </Route>
          :
          <Route element={<CmsLayout />}>
            :

{/* <Route element={<GridMoitorView />}>   */}
            {
              identifier === "pktflm" || identifier === "nhstdz" ?
              ''
              :
              <Route  path="/store-front"  element={<PrivateRoute><StoreFront /></PrivateRoute>} />
            }


{/* <Route element={<GridMoitorView />}/>  */}
            <Route path="/" element={<Viewership />} />
            <Route path="/viewership" element={<Viewership />} />
            <Route path="/scheduling" element={<Scheduling />} />
            {/* <Route path="/playlists" element={<PlaylistsMain/>} /> */}
            {/* <Route path="/schedulingDetail" element={<ScheduleDetail />} /> */}
            <Route path="/scheduling-linear" element={<SchedulingLinear />} />
            {/* <Route path="/schedulingLoop" element={<ScheduleLoop />} /> */}
            <Route path="/schedule-Loop" element={<SchedulingLoopNew />} />
            <Route path="/asset" element={<AssetManagement />} />
            <Route path="/cmsChannelDetail" element={<CmsChannelDetail />} />
            <Route path="/monitor" element={<Monitor />} />
            {/* <Route path="/monitorgridview" element={<MonitorGridView />} /> */}
            {/* <Route path="/gridMonitor" element={<GridMonitor />}/> */}
            
           
            <Route
              path="/channel-actions"
              element={<Channelactionscomponent />}
            />
            <Route path="/channel" element={<ChannelSetup />} />
            <Route path="/channelCreation" element={<ChannelCreation />} />
            <Route path="/addDistributionPlatform" element={<AddDistributionPlatform />} />
            <Route path="/master-settings" element={<MasterSettings />} />
            <Route path="/meta-edit" element={<EditableGrid />} />
            {/* <Route path="/store-front" element={<StoreFront />} /> */}

            {/* <Route path="/store-front" element={<StoreFront />} /> */}
            
            <Route path="/add-super-tenant" element={<SuperTenantAdd />} />
            <Route path="/add-tenant" element={<TenantAdd />} />
            
            <Route path="/user-add" element={<UserAdd />} />
            {/* Add other nested routes for the "CmsLayout" here */}
            <Route path="*" element={<NotFoundRedirect />} />
          </Route>
          
                  // </Route>

        }

<Route element={<GridMonitorView />}>
<Route path="/gridMonitor" element={<GridMonitor />}/> 
</Route>


        {/* <Route exact path="/login" component={LoginComponent} />
    {/* Define other routes here */}
        {/* <Route path="/viewership" component={ViewershipComponent} />
      <Route path="/dashbord" component={DashboardComponent} />
      <Route path="/dashbord-channel" component={DashbordChannelComponent} />
      <Route path="/channel-actions" component={ChannelActionsComponent} />
      <Route path="/scheduling" component={SchedulingComponent} />
      <Route path="/channel-setup" component={ChannelSetupComponent} />
      <Route path="/channel-creation" component={ChannelCreationComponent} />
      <Route path="/channel-upload" component={ChannelUploadComponent} /> */}
      </Routes>
    </Router>
  );
}

export default AppRoutes;
