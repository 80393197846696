import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import "./schedulingLoopNew.scss";
import { useSelector } from "react-redux";
import { 
    Schedules_list,
    Schedules_Datalist_Loop, 
    ScheduleLoop_update,
    programValidate,
    adValidate,
} from "../../../api/api";
import icroundsearch from "../../../assets/images/ic_round-search.svg";
import { toast, ToastContainer } from "react-toastify";
import deleteIcon from '../../../assets/images/delete_forever.png';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import editIcon from '../../../assets/images/edit-outline.svg';
import ellipse from "../../../assets/images/Ellipse.png";
import { 
    fetchSchedule,
    update_schedule
} from "./utils/fetchAndUpdateLoopApi";
import { handleDrop, handelProgramDoubleClick } from "./utils/handleDropLoop";
import { 
    channelDragStart,
    handleDragStart,
    handleDragOver,
    handleDragOver2,
} from "./utils/handleDragloop";
import { handleProgramDelete, deleteEvent } from "./utils/handleDeleteLoop";
import { 
    addAdsOnClick,
    millisecondsToHuman,
    verifyMilliSeconds,
    totalScheduleTimeFun,
    generateTimeSlots,
    calculateMinutes,
    getAssetInfo,
    setAdsTime,
    selectedFiller,
    searchProgram
} from "./utils/utils";
import { Left } from "../scheduling-linear-new/components/left/left";
import { SearchBar } from "../scheduling-linear-new/components/left/child-component/SearchBar";
import { ChannelListing } from "../scheduling-linear-new/components/left/child-component/ChannelListing";
import { AdsButton } from "../scheduling-linear-new/components/left/child-component/AdsButton";
import { Right } from "../scheduling-linear-new/components/right/Right";
import { HeaderSection } from "./components/HeaderSection";
import { MiddleContent } from "./components/MiddleContent";
import { RightSideContent } from "./components/RightSideContent";
import { ZoomControls } from "./components/ZoomControls";
import { ScheduleDetails } from "./components/ScheduleDetails";
import { TableBody } from "./components/TableBody"; 
import { TimeSlots } from "./components/TimeSlots";
import { AdBreaksContainer } from "./components/AdBreaksContainer";
import { DraggableTableRow } from "./components/DraggableTableRow";
import AssetInfoCard from "./components/AssetInfoCard";
import AdBreaksTable from "./components/AdBreaksTable";
import { NavigationButtons } from "./components/NavigationButtons";

function SchedulingLoopNew() {
    const locationState = useLocation();
    const [programList, setProgramList] = useState();
    const [originalProgramList, setOriginalProgramList] = useState([]);
    const [promoList, setPromoList] = useState();
    const [fillerList, setFillerist] = useState();
    const [gridList, setGridList] = useState();
    const [loaderStatus, setLoaderStatus] = useState(false);
    const [draggableType, setDraggableType] = useState(null);
    const [totalDuration, setTotalDuration] = useState({
        duration: "00:00:00",
        totalNum: 0,
    });
    const [totalVideoDuration, setTotalVideoDuration] = useState({
        duration: "00:00:00",
        totalNum: 0,
    });
    const [totalAdDuration, setTotalAdDuration] = useState({
        duration: "00:00:00",
        totalNum: 0,
    });
    const [totalPromosDuration, setTotalPromosDuration] = useState({
        duration: "00:00:00",
        totalNum: 0,
    });
    const [selectedTab, setSelectedTab] = useState("Programs");
    const [zoomInOut, setZoomInOut] = useState(10);
    const [assetInfo, setAssetInfo] = useState(null);
    const [categoryTab, setCategoryTab] = useState("Ads");
    const [seqId, setSeqId] = useState(1);
    const [hoveredId, setHoveredId] = useState(null);    
    const [linePosition, setLinePosition] = useState(null);
    const [hoverTime, setHoverTime] = useState(null);
    const [timeSlots, setTimeSlots] = useState([]);
    const [singleTimeSlots, setSingleTimeSlots] = useState([]);
    const [searchedText, setSearchedText] = useState("");
    const draggedItem = useRef();    
    const {
        appThemeReducer: { appTheme },
    } = useSelector((state) => state);    

    const totalScheduleTimeFunc = () => {
        totalScheduleTimeFun(
            gridList,
            singleTimeSlots,
            setTimeSlots,
            setTotalDuration,
            setTotalVideoDuration,
            setTotalAdDuration,
            setTotalPromosDuration
        )
    }

    const adAdsOnClick = (sequenceId) => {
        addAdsOnClick(
            sequenceId,
            locationState,
            adValidate,
            gridList,
            verifyMilliSeconds,
            millisecondsToHuman,
            setGridList,
            toast
        )
    }
    
    const deleteAdEvent = (seqId, sequenceId) => {
        deleteEvent(
            seqId, 
            sequenceId,
            gridList,
            setGridList,
            millisecondsToHuman
        )
    }

    const programDelete = (index) => {
        handleProgramDelete(
            index,
            gridList,
            setGridList,
            millisecondsToHuman
        )
    }

    const handleDragStarts = (event) => {
        handleDragStart(
            event,
            draggedItem
        )
    }

    const handleDragOvers = (event) => {
        handleDragOver(
            event,
            draggedItem,
            setDraggableType
        )
    }

    const handleDragOvers2 = (event, id) => {
        handleDragOver2(
            event,
            id,
            setHoveredId,
            zoomInOut,
            setLinePosition,
            setHoverTime
        )
    }
    
    const handelProgramOnClick = (data, index) => {
        handelProgramDoubleClick (
            data, 
            index,
            gridList,
            setGridList
        )
    }
    
    const handleAdsAndProgramDrop = (event) => {
        handleDrop(
            event,
            setHoveredId,
            setLinePosition,
            draggedItem,
            setSeqId,
            gridList,
            setHoverTime,
            adValidate,
            verifyMilliSeconds,
            hoverTime,
            millisecondsToHuman,
            toast,
            setCategoryTab,
            setGridList,
            programValidate
        )
    }

    const fetchLoopData = () => {
        fetchSchedule(
            setLoaderStatus,
            locationState,
            Schedules_list,
            setProgramList,
            setOriginalProgramList,
            setFillerist,
            setPromoList,
            Schedules_Datalist_Loop,
            millisecondsToHuman,
            setAssetInfo,
            setGridList
        )
    }

    const updateLoopData = () => {
        update_schedule(
            setLoaderStatus,
            ScheduleLoop_update,
            gridList,
            locationState,
            toast,
            fetchLoopData
        )
    }

    const toGetAssetInfo = (sequenceId) => {
        getAssetInfo(
            sequenceId,
            gridList,
            setAssetInfo,
            setSeqId
        )
    }

    const updateAdsTime = (e, adSqId, sequenceId) => {
        setAdsTime(
            e, 
            adSqId, 
            sequenceId,
            gridList,
            toast,
            setGridList
        )
    }

    const selectedFillers = (id, adSqId, sequenceId) => {
        selectedFiller(
            id, 
            adSqId, 
            sequenceId,
            fillerList,
            gridList,
            setGridList
        )
    }

    const searchPrograms = (text) => {
        searchProgram(
            text,
            originalProgramList,
            setProgramList,
            setSearchedText
        )
    }

    useEffect(() => {
        totalScheduleTimeFunc();
        toGetAssetInfo(seqId);
    },[gridList])
    
    useEffect(()=> {
        fetchLoopData();
    },[locationState])

    // Set the time slots in state when the component mounts
    useEffect(() => {
        const slots = generateTimeSlots();
        setTimeSlots(slots);
        setSingleTimeSlots(slots);
    }, []);

    const handleZoomIn = () => {
        setZoomInOut((prevZoom) => Math.min(prevZoom + 1, 25));
    };
    
    const handleZoomOut = () => {
        setZoomInOut((prevZoom) => Math.max(prevZoom - 1, 1));
    };

    const handlecategoryTab = (tab) => {
        setCategoryTab(tab);
    };

    return (
        <div className={`content-body SchedulingDetail SchedulingLoop scheduling-loop-content-body ${appTheme}`}>
            <div className="dashboard-content flex schedule-loop-view">
                <Left>
                    <SearchBar appTheme={appTheme} searchedText={searchedText} searchPrograms={searchPrograms} />
                    <ChannelListing programList={programList} promoList={promoList} fillerList={fillerList} locationState={locationState} channelDragStart={channelDragStart} handelProgramOnClick={handelProgramOnClick} draggedItem={draggedItem} />
                    <AdsButton locationState={locationState} draggedItem={draggedItem} channelDragStart={channelDragStart} />
                </Left>
                        
                <Right customStyle={{width: "100%"}}>
                    <div className="top-content">
                        <HeaderSection locationState={locationState} updateLoopData={updateLoopData} loaderStatus={loaderStatus} />
                    </div>
                    <div className="bottom-content row mt-2">
                        <MiddleContent>
                            <div className="middle-up row">
                                <ZoomControls handleZoomIn={handleZoomIn} handleZoomOut={handleZoomOut} />
                                <ScheduleDetails appTheme={appTheme} totalDuration={totalDuration} totalVideoDuration={totalVideoDuration} totalAdDuration={totalAdDuration} totalPromosDuration={totalPromosDuration} />
                            </div>
                            <div className="middle-down">
                                <TableBody handleDragStarts={handleDragStarts} handleDragOvers={handleDragOvers} handleAdsAndProgramDrop={handleAdsAndProgramDrop}>
                                    {/* Render timeSlots in a separate loop */}
                                    <TimeSlots timeSlots={timeSlots} zoomInOut={zoomInOut} />
                                    {/* Render gridList as you already have it */}
                                    <div className="grid-list-container col-10 p-2">
                                    { gridList?.length !== 0?
                                        gridList?.map((data, index) => {
                                            return (
                                                <section 
                                                    className="d-flex px-2" 
                                                    id={`sequenceId${data?.sequence}`}
                                                    onDragOver={(e) => handleDragOvers2(e, data.sequence)}
                                                >
                                                    <AdBreaksContainer calculateMinutes={calculateMinutes} data={data} zoomInOut={zoomInOut} linePosition={linePosition} hoveredId={hoveredId} draggableType={draggableType} hoverTime={hoverTime} />
                                                    <DraggableTableRow toGetAssetInfo={toGetAssetInfo} data={data} locationState={locationState} index={index} calculateMinutes={calculateMinutes} zoomInOut={zoomInOut} assetInfo={assetInfo} millisecondsToHuman={millisecondsToHuman} programDelete={programDelete} deleteIcon={deleteIcon} />
                                                </section>
                                            )
                                        })
                                        :
                                        <td colspan="5" class="text-center no-data">
                                            No data has been scheduled.
                                        </td>
                                    }
                                    </div>
                                </TableBody>
                            </div>
                        </MiddleContent>
                        <RightSideContent>
                            <AssetInfoCard assetInfo={assetInfo} millisecondsToHuman={millisecondsToHuman} appTheme={appTheme} categoryTab={categoryTab} />
                            <AdBreaksTable appTheme={appTheme} categoryTab={categoryTab} handlecategoryTab={handlecategoryTab} locationState={locationState} assetInfo={assetInfo} selectedFillers={selectedFillers} fillerList={fillerList} millisecondsToHuman={millisecondsToHuman} updateAdsTime={updateAdsTime} deleteAdEvent={deleteAdEvent} deleteIcon={deleteIcon} adAdsOnClick={adAdsOnClick} />
                            <NavigationButtons appTheme={appTheme} programDelete={programDelete} assetInfo={assetInfo} toGetAssetInfo={toGetAssetInfo} gridList={gridList} />
                        </RightSideContent>
                    </div>
                </Right>
            </div>
            <ToastContainer />
            {
                loaderStatus &&
                <div className="loader-container">
                    <div className="loader"></div>
                    <p></p>
                </div>
            }
        </div>
    )
}

export default SchedulingLoopNew;