
import React from 'react';
import Select, { components } from 'react-select';
import styles from './editable-grid.module.scss';

const MultiSelectCell = ({ row, column, options, updateRow }) => {
  const value = row.original[column.id] || [];

  const handleChange = (selectedOptions) => {
    updateRow(row.index, column.id, selectedOptions || []);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: '#333',
      borderColor: '#444',
      color: '#fff',
      boxShadow: 'none',
      '&:hover': {
        borderColor: '#666',
      },
      minHeight: '36px',
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#333',
      color: '#fff',
      width: '100%',
      minWidth: '100%',
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#555' : '#333',
      color: '#fff',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      minWidth: '100%',
      '&:hover': {
        backgroundColor: '#444',
      },
      padding: '8px',
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#555',
      margin: '2px',
      display: 'inline-flex',
      flexShrink: 0,
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: '#fff',
      padding: '2px 4px',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: '#fff',
      padding: '2px',
      '&:hover': {
        backgroundColor: '#777',
        color: '#fff',
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#aaa',
    }),
    input: (provided) => ({
      ...provided,
      color: '#fff',
      flexGrow: 1,
    }),
    valueContainer: (provided) => ({
      ...provided,
      display: 'flex',
      flexWrap: 'wrap',
      padding: '2px',
      width: '100%',
    }),
  };

  const CustomOption = (props) => {
    const { data, isSelected, innerRef, innerProps } = props;
    return (
      <div
        ref={innerRef}
        {...innerProps}
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '8px',
          width: '100%',
        }}
      >
        <input
          type="checkbox"
          checked={isSelected}
          readOnly
          style={{ marginRight: '8px' }}
        />
        {data.name}
      </div>
    );
  };

  return (
    <div style={{ width: '100%' }}>
      <Select
        isMulti
        options={options}
        value={value}
        onChange={handleChange}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.name}
        styles={customStyles}
        className={styles.multiSelect}
        placeholder="Select..."
        components={{ Option: CustomOption }}
        menuPortalTarget={document.body}
      />
    </div>
  );
};

export default MultiSelectCell;