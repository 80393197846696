// import React, { useEffect, useState } from "react";
// import { Link, useLocation, useNavigate } from "react-router-dom"; 
// import { useDispatch, useSelector } from "react-redux";
// import asseticon from "../../assets/images/asset-icon.svg";
// import channelicon from "../../assets/images/channel-icon.svg";
// import viewershipicon from "../../assets/images/viewership-icon.svg";
// import cmsicon from "../../assets/images/cms-dashboard.png";
// import Engineicon from "../../assets/images/Engine-icon.svg";
// import scheduling from "../../assets/images/scheduling-icon.svg";
// import management from "../../assets/images/management-icon.svg";
// import master from "../../assets/images/master-settings.svg";
// import backicon from "../../assets/images/back-icon.svg";
// import storefrontIcon from "../../assets/images/store-front.svg";
// import tenantIcon from "../../assets/images/tenant.svg";
// import UserManagement from "../../assets/images/user-management.svg";
// import "../../assets/css/style.scss";
// import { Profile_data } from "../../service/Constant";
// import { adminModeAction, } from "../../Redux/slices";
// import { permissionDetails } from "../../utility/localStoageData";

// function SlideBar() {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const [menuStatus, setMenuStatus] = useState(false);
//   const location = useLocation(); 
//   const [activeItem, setActiveItem] = useState(""); 
//   const [storeFrontAccess, setStoreFrontAccess] = useState("");
//   const emailList = ["saurabh@runn.tv", "manish@runn.tv", "amit@runn.tv", "all.films@partner.com"];
//   const [permission, setpermission] = useState(null);
//   const [identifier, setIdentifier] = useState("");

//   const {
//     appThemeReducer: { appTheme },
//     adminModeReducer: { adminMode },  
//   } = useSelector((state) => state);
  
//   const backMenu = () => {
//     if (menuStatus) {
//       setMenuStatus(false);
//     } else {
//       setMenuStatus(true);
//     }
//   };
  
//   const handleItemClick = (itemValue) => {
//     setActiveItem(itemValue); // Set the active item when it's clicked
//   };
//   const accessStore = async () => {
//     const tenantLocalData = localStorage.getItem('tenantInfo');
//     const tenantLocalDataParse = await JSON.parse(tenantLocalData);
//     setStoreFrontAccess(tenantLocalDataParse?.superTenant?.code);
//     setIdentifier(tenantLocalDataParse?.code);
//   };

//   const disableAdminMode = () => {    
//     dispatch(adminModeAction(false));
//     navigate("/asset");
//   }

//   const getPermission = () => {
//     let findEdit = permissionDetails();
//     setpermission(findEdit);
//    }

//   useEffect(()=>{
//     getPermission();
//     accessStore();
//   },[])

//   return (
//     <div
//       className={
//         menuStatus
//           ? `sidebar-menu menu-icon ${(adminMode || appTheme == "dark") ? "dark" : "light"}`
//           : `sidebar-menu ${(adminMode || appTheme == "dark") ? "dark" : "light"}`
//       }
//     >
//       <div className="back-menu">
//         <button onClick={backMenu}>
//           <img src={backicon} alt="Back Icon" />
//         </button>
//       </div>
//       <div className="menu">
//         <ul>
//           {!adminMode && <>  
//           {
//             <li 
//               className={
//                 location.pathname === "/viewership" || 
//                 location.pathname == "/" 
//                 ? "active" : ""
//               }
//             >
//               <Link to="/viewership">
//                 <img src={viewershipicon} alt="viewership Icon" />
//                 <span>Viewership Dashboard</span>
//               </Link>
//             </li>
//           }
//           {
//             permission?.includes("ASSET_MANAGEMENT_EDIT") || permission?.includes("ASSET_MANAGEMENT_READ")?
//             <li
//               className={
//                 location.pathname == "/Asset" ||
//                 location.pathname == "/asset" ||
//                 location.pathname === "/CmsChannelDetail" ||
//                 location.pathname === "/channel-actions"
//                   ? "active"
//                   : ""
//               }
//             >
//               <Link to="/asset">
//                 <img src={asseticon} alt="asset Icon" />
//                 <span>Asset Management</span>
//               </Link>
//             </li>
//             :
//             ''
//           }   
//           {
//             permission?.includes("CHANNEL_SETUP_EDIT") || permission?.includes("CHANNEL_SETUP_READ")?
//             <li
//               className={
//                 location.pathname === "/channel" ||
//                 location.pathname === "/channelCreation"
//                   ? "active"
//                   : ""
//               }
//             >
//               <Link to="/channel">
//                 <img src={channelicon} alt="channel Icon" />
//                 <span>Channel Setup</span>
//               </Link>
//             </li>
//             :
//             ''
//           }     
//           <li className={location.pathname === "/ruleEngine" ? "active" : ""}>
//             <Link>
//               <img src={Engineicon} alt="Engine Icon" />
//               <span>Rule Engine</span>
//             </Link>
//           </li>
//           {
//             permission?.includes("SCHEDULING_EDIT") || permission?.includes("SCHEDULING_READ")?
//             <li
//               className={
//                 location.pathname === "/scheduling" ||
//                 location.pathname === "/schedulingDetail" ||
//                 location.pathname === "/schedulingLoop"
//                   ? "active"
//                   : ""
//               }
//             >
//               <Link to="/scheduling">
//                 <img src={scheduling} alt="scheduling Icon" />
//                 <span>Scheduling</span>
//               </Link>
//             </li>
//             :
//             ''
//           }
//           {/* <li className={location.pathname === "/inventory" ? "active" : ""}>
//             <Link to="/inventory">
//               <img src={management} alt="Management Icon" />
//               <span>Inventory Management</span>
//             </Link>
//           </li> */}
//           {
//             permission?.includes("MASTER_SETTINGS_EDIT") || permission?.includes("MASTER_SETTINGS_READ")?
//             <li
//               className={location.pathname === "/master-settings" ? "active" : ""}
//             >
//               <Link to="/master-settings">
//                 <img src={master} alt="Master Settings" />
//                 <span>Master Settings</span>
//               </Link>
//             </li>
//             :
//             ''
//           }
//           {
//             permission?.includes("STOREFRONT_EDIT") || permission?.includes("STOREFRONT_READ")?
//             storeFrontAccess === "cloudtv" || identifier === "pktflm"? 
//             ''
//             :
//             <li className={location.pathname === "/store-front" ? "active" : ""}>
//               <Link to="/store-front">
//                 <img src={storefrontIcon} alt="Store Front" />
//                 <span>Store Front</span>
//               </Link>
//             </li>
//             :
//             ''
//           }
//           </>
//           }
//           {/* {adminMode && <>
//             {
//               identifier !== "default"?
//               <>
//                 <li
//                   onClick={disableAdminMode}
//                 >
//                   <Link >
//                     <img src={cmsicon} alt="CMS Dashboard" />
//                     <span>CMS Dashboard</span>
//                   </Link>
//                 </li>
//                 <li
//                   className={
//                     location.pathname === "/viewership"
//                       ? "active"
//                       : ""
//                   }
//                 >
//                   <Link to="/viewership">
//                     <img src={viewershipicon} alt="viewership Icon" />
//                     <span>Viewership Dashboard</span>
//                   </Link>
//                 </li>
//               </>
//               :
//               ''
//             }
//             <li
//               className={
//                 location.pathname === "/user-management" ||
//                 location.pathname === "/user-add"
//                   ? "active"
//                   : ""
//               }
//             >
//               <Link to="/user-management">
//                 <img src={UserManagement} alt="User Management" />
//                 <span>User Management</span>
//               </Link>
//             </li>
//             <li //</>className={location.pathname === "/tenant-onboarding" ? "active" : ""}
//               className={
//                 location.pathname === "/tenant-onboarding" ||
//                 location.pathname === "/add-super-tenant" ||
//                 location.pathname === "/add-tenant" 
//                   ? "active"
//                   : ""
//               }
//             >
//               <Link to="/tenant-onboarding">
//                 <img src={tenantIcon} alt="Tenant onboarding" />
//                 <span>Tenant Onboarding</span>
//               </Link>
//             </li>
//           </>} */}
//         </ul>
//       </div>
//     </div>
//   );
// }

// export default SlideBar;


import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom"; // Import Link and useLocation
import { useDispatch, useSelector } from "react-redux";
import asseticon from "../../assets/images/asset-icon.svg";
import channelicon from "../../assets/images/channel-icon.svg";
import viewershipicon from "../../assets/images/viewership-icon.svg";
import cmsicon from "../../assets/images/cms-dashboard.png";
import Engineicon from "../../assets/images/Engine-icon.svg";
import scheduling from "../../assets/images/scheduling-icon.svg";
import management from "../../assets/images/management-icon.svg";
import master from "../../assets/images/master-settings.svg";
import backicon from "../../assets/images/back-icon.svg";
import storefrontIcon from "../../assets/images/store-front.svg";
import tenantIcon from "../../assets/images/tenant.svg";
import UserManagement from "../../assets/images/user-management.svg";
import "../../assets/css/style.scss";
import { Profile_data } from "../../service/Constant";
import { adminModeAction, } from "../../Redux/slices";
import { permissionDetails } from "../../utility/localStoageData";
import PlayListIcon from '../../assets/svg/playlists/playlist-Icon.svg'
import Tooltip from "../Tooltip";
import ErrorPopup from "../popup/ErrorPopup";

function SlideBar({
    setErrorMessage,
    setErrorbox,
    seteventTrim
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [menuStatus, setMenuStatus] = useState(false);
  const location = useLocation(); // Get the current location from react-router-dom
  const [activeItem, setActiveItem] = useState(""); // State to keep track of the active item
  const [storeFrontAccess, setStoreFrontAccess] = useState("");
  const emailList = ["saurabh@runn.tv", "manish@runn.tv", "amit@runn.tv", "all.films@partner.com"];
  const [permission, setpermission] = useState(null);
  const [identifier, setIdentifier] = useState("");

  const {
    appThemeReducer: { appTheme },
    adminModeReducer: { adminMode },  
  } = useSelector((state) => state);
  const backMenu = () => {
    if (menuStatus) {
      setMenuStatus(false);
    } else {
      setMenuStatus(true);
    }
  };
  
  const handleItemClick = (itemValue) => {
    setActiveItem(itemValue); // Set the active item when it's clicked
  };
  const accessStore = async () => {
    const tenantLocalData = localStorage.getItem('tenantInfo');
    const tenantLocalDataParse = await JSON.parse(tenantLocalData);
    setStoreFrontAccess(tenantLocalDataParse?.superTenant?.code);
    setIdentifier(tenantLocalDataParse?.code);
  };

  const disableAdminMode = () => {    
    dispatch(adminModeAction(false));
    navigate("/asset");
  }

  const getPermission = () => {
    let findEdit = permissionDetails();
    setpermission(findEdit);
   }

  useEffect(()=>{
    getPermission();
    accessStore();
  },[]) 

  const checkLinearEdit = (e, path) => {
        if (localStorage.getItem("isLinearEdit") === "true") {
            e.preventDefault(); // Prevent redirection
            setErrorMessage("Are you sure you want to leave this page without saving your changes?");
            setErrorbox(true);
            seteventTrim(() => async() => {
                localStorage.setItem("isLinearEdit", JSON.stringify(false));
                navigate(path);
            })
        }
    }

  return (
    <>
        <div
            className={
                menuStatus || 
                location.pathname === "/schedule-Loop" ||
                location.pathname === "/schedulingDetail" ||
                location.pathname === "/scheduling-Details" ||
                location.pathname === "/scheduling-linear"
                ? `sidebar-menu menu-icon ${(adminMode || appTheme == "dark") ? "dark" : "light"}`
                : `sidebar-menu ${(adminMode || appTheme == "dark") ? "dark" : "light"}`
            }
            >
            <div className="back-menu">
                <a onClick={backMenu}>
                <img src={backicon} alt="Back Icon" />
                </a>
            </div>
            <div className="menu">
                <ul>
                {!adminMode && <>  
                {
                    <li 
                    className={
                        location.pathname === "/viewership" || 
                        location.pathname == "/" 
                        ? "active" : ""
                    }
                    data-title="Viewership Dashboard"
                    
                    >
                    <Link 
                        to="/viewership"
                        onClick={(e)=>checkLinearEdit(e, "/viewership")}
                    >
                        <img src={viewershipicon} alt="viewership Icon" />
                        <span>Viewership Dashboard</span>
                    </Link>
                    </li>
                }
                {
                    
                    permission?.includes("ASSET_MANAGEMENT_EDIT") || permission?.includes("ASSET_MANAGEMENT_READ")?
                    <li
                    className={
                        location.pathname == "/Asset" ||
                        location.pathname == "/asset" ||
                        location.pathname === "/CmsChannelDetail" ||
                        location.pathname === "/channel-actions"
                        ? "active"
                        : ""
                    }
                    data-title="Asset Management"
                    >
                    <Link 
                        to="/asset" 
                        onClick={(e)=>checkLinearEdit(e, "/asset")}
                    >
                        <img src={asseticon} alt="asset Icon" />
                        <span>Asset Management</span>
                    </Link>
                    </li>
                    
                    :
                    ''
                }  
                {/* {
                    
                    <li
                    className={
                        location.pathname === "/playlists" ||
                        location.pathname === "/playlistsCreation"
                        ? "active"
                        : ""
                    }
                    title="Playlists"
                    >
                    <Link to="/playlists">
                        <img src={PlayListIcon} alt="playlist Icon" />
                        <span>Playlists</span>
                    </Link>
                    </li>
                    
                
                }     */}
                {
                    permission?.includes("CHANNEL_SETUP_EDIT") || permission?.includes("CHANNEL_SETUP_READ")?
                    <li
                    className={
                        location.pathname === "/channel" ||
                        location.pathname === "/channelCreation"
                        ? "active"
                        : ""
                    }
                    data-title="Channel Setup"
                    >
                    <Link 
                        to="/channel" 
                        onClick={(e)=>checkLinearEdit(e, "/channel")}
                    >
                        <img src={channelicon} alt="channel Icon" />
                        <span>Channel Setup</span>
                    </Link>
                    </li>
                    :
                    ''
                }     
                <li className={location.pathname === "/ruleEngine" ? "active" : ""}
                    data-title="Rule Engine"
                >
                    <Link onClick={(e)=> e.preventDefault()}>
                    <img src={Engineicon} alt="Engine Icon" />
                    <span>Rule Engine</span>
                    </Link>
                </li>
                {
                    permission?.includes("SCHEDULING_EDIT") || permission?.includes("SCHEDULING_READ")?
                    <li
                    className={
                        location.pathname === "/scheduling" ||
                        location.pathname === "/schedulingDetail" ||
                        location.pathname === "/schedulingLoop" ||
                        location.pathname === "/schedule-Loop"
                        ? "active"
                        : ""
                    }
                    data-title="Scheduling"
                    >
                    <Link 
                        to="/scheduling" 
                        onClick={(e)=>checkLinearEdit(e, "/scheduling")}
                    >
                        <img src={scheduling} alt="scheduling Icon" />
                        <span>Scheduling</span>
                    </Link>
                    </li>
                    :
                    ''
                }
                {/* <li className={location.pathname === "/inventory" ? "active" : ""}>
                    <Link to="/inventory">
                    <img src={management} alt="Management Icon" />
                    <span>Inventory Management</span>
                    </Link>
                </li> */}
                {
                    permission?.includes("MASTER_SETTINGS_EDIT") || permission?.includes("MASTER_SETTINGS_READ")?
                    <li
                    className={location.pathname === "/master-settings" ? "active" : ""}
                    data-title="Master Settings"
                    >
                    <Link 
                        to="/master-settings" 
                        onClick={(e)=>checkLinearEdit(e, "/master-settings")}
                    >
                        <img src={master} alt="Master Settings" />
                        <span>Master Settings</span>
                    </Link>
                    </li>
                    :
                    ''
                }
                {
                    permission?.includes("STOREFRONT_EDIT") || permission?.includes("STOREFRONT_READ")?
                    storeFrontAccess === "cloudtv" || identifier === "pktflm" || identifier === "nhstdz" ? 
                    ''
                    :
                    <li className={location.pathname === "/store-front" ? "active" : ""}
                    data-title="Store Front"
                    >
                    <Link 
                        to="/store-front" 
                        onClick={(e)=>checkLinearEdit(e, "/store-front")}
                    >
                        <img src={storefrontIcon} alt="Store Front" />
                        <span>Store Front</span>
                    </Link>
                    </li>
                    :
                    ''
                }
                </>
                }
                {/* {adminMode && <>
                    {
                    identifier !== "default"?
                    <>
                        <li
                        onClick={disableAdminMode}
                        >
                        <Link >
                            <img src={cmsicon} alt="CMS Dashboard" />
                            <span>CMS Dashboard</span>
                        </Link>
                        </li>
                        <li
                        className={
                            location.pathname === "/viewership"
                            ? "active"
                            : ""
                        }
                        >
                        <Link to="/viewership">
                            <img src={viewershipicon} alt="viewership Icon" />
                            <span>Viewership Dashboard</span>
                        </Link>
                        </li>
                    </>
                    :
                    ''
                    }
                    <li
                    className={
                        location.pathname === "/user-management" ||
                        location.pathname === "/user-add"
                        ? "active"
                        : ""
                    }
                    >
                    <Link to="/user-management">
                        <img src={UserManagement} alt="User Management" />
                        <span>User Management</span>
                    </Link>
                    </li>
                    <li //</>className={location.pathname === "/tenant-onboarding" ? "active" : ""}
                    className={
                        location.pathname === "/tenant-onboarding" ||
                        location.pathname === "/add-super-tenant" ||
                        location.pathname === "/add-tenant" 
                        ? "active"
                        : ""
                    }
                    >
                    <Link to="/tenant-onboarding">
                        <img src={tenantIcon} alt="Tenant onboarding" />
                        <span>Tenant Onboarding</span>
                    </Link>
                    </li>
                </>} */}
                </ul>
            </div>
        </div>
    </>
  );
}

export default SlideBar;


