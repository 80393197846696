import { useState } from "react";
import { useNavigate } from "react-router-dom"; 
import "./ChannelPageButton.scss";
import DistributionPlatform from "./DistributionPlatform/DistributionPlatform";
import ChannelSetupNew from "./ChannelSetupNew/channelSetupNew";

const ChannelPage = () => {
  const [selectedTab, setSelectedTab] = useState("channelSetup");
  const [showDropdown, setShowDropdown] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);

  const navigate = useNavigate(); 

  const goToCreateChannel = () => {
    navigate('/channelCreation');
  };
  const goToAddDistributionPlatform = () => {
    navigate('/addDistributionPlatform');
  };

  return (
    <div>
      <div className="channel-Distribution">
        <div className="channel-distrubution-button">
          <button
            className={`${selectedTab === "channelSetup" ? "btn-red btn-primary" : "btn-Distribution"}`}
            onClick={() => setSelectedTab("channelSetup")}
          >
            Channel Setup
          </button>
          <button
            className={`btn-Distribution ${selectedTab === "distribution" ? "btn-red" : ""}`}
            onClick={() => setSelectedTab("distribution")}
          >
            Distribution Platforms
          </button>
        </div>
        <div className="dropdown-container">
          <button className="btn-add" onClick={() => setShowDropdown(!showDropdown)}>
            + Add
          </button>
          {showDropdown && (
            <div className="dropdown-menu">
              <button 
                className={`dropdown-item ${activeDropdown === "channel" ? "active" : ""}`} 
                onClick={goToCreateChannel} 
              >
                New Channel
              </button>
              <button 
                className={`dropdown-item ${activeDropdown === "distribution" ? "active" : ""}`} 
                onClick={goToAddDistributionPlatform}
              >
                New Distribution Platform
              </button>
            </div>
          )}
        </div>
      </div>
      {selectedTab === "channelSetup" && (
        <div className="content">
          <div className="existing-screen">
            <ChannelSetupNew/>
          </div>
        </div>
      )}

      {selectedTab === "distribution" && (
        <div className="black-screen">
          <DistributionPlatform />
        </div>
      )}
    </div>
  );
};

export default ChannelPage;
