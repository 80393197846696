import React from 'react';

const LoadingSpinner = () => (
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
    <div
      style={{
        width: '40px',
        height: '40px',
        border: '4px solid #f3f3f3',
        borderTop: '4px solid #D22F26',
        borderRadius: '50%',
        animation: 'spin 1s linear infinite',
      }}
    ></div>
  </div>
);

export default LoadingSpinner;