import React from 'react';
import SearchIcon from '../../assets/svg/metaEdit/meta-search.svg';

const SearchBar = ({ searchText, setSearchText }) => (
  <div className="meta-search-container">
    <input
      type="text"
      value={searchText}
      onChange={(e) => setSearchText(e.target.value)}
      placeholder="Search using file name"
      className="meta-search-input"
    />
    <img src={SearchIcon} alt="search" className="meta-search-icon" />
  </div>
);

export default SearchBar;