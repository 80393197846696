import Modal from 'react-bootstrap/Modal';
import styles from "../../AdsPopup.module.scss";
import '../components/adsInfo.scss';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import deleteIcon from '../../../../assets/images/delete_forever.png';
import { useEffect, useState } from 'react';
import { toast, ToastContainer } from "react-toastify";
import editoutline from "../../../../assets/images/edit-outline.svg";

const AdsInfo = ({
    show, 
    handleClose, 
    assetInfo, 
    millisecondsToHuman, 
    formatTime,
    appTheme, 
    categoryTab, 
    handlecategoryTab,
    permission,
    selectedFillers,
    fillerList,
    updateAdsTime,
    deleteAdEvent,
    adAdsOnClick,
    programDelete,
    toGetAssetInfo,
    updateProgramTime,
    verifyMilliSeconds,
    gridList,
    setPanelHoverTime,
    setPanelLinePosition,
    setPanelHoverDate,
    locationState,
    updateModalData
}) => {

    const [programInfo, setProgramInfo] = useState({});
    const [editDisable, setEditDisable] = useState(true);

    useEffect(() => {
        setProgramInfo(assetInfo);
    },[assetInfo])

    const setProgramTime = (
        e, 
        programUuid, 
        sequenceId, 
        panelDate,
    ) => {
        let combinedTimeInMillis = panelDate + verifyMilliSeconds(e);
        let isOverlap = false;
        let updatedGridList = [...gridList];
        
        updatedGridList = updatedGridList.map((grid) => {
            let isGridEdited = false;
            const updatedScheduleItems = grid.scheduleItems.map((scheduleItem) => {
                if (scheduleItem.uuid === programUuid) {
                    const updatedStartAt = combinedTimeInMillis;
                    const updatedEndAt = updatedStartAt + scheduleItem.totalDuration;
    
                    // Check for overlap with other schedule items in the entire gridList
                    const isConflict = updatedGridList.some((grid) => {
                        return grid.scheduleItems.some((item) => {
                            if (item.uuid !== scheduleItem.uuid) {
                                return (
                                    (updatedStartAt > item.startAt && updatedStartAt < item.endAt) || // Starts within another program
                                    (updatedEndAt > item.startAt && updatedEndAt < item.endAt) ||   // Ends within another program
                                    (updatedStartAt < item.startAt && updatedEndAt > item.endAt)  // Envelops another program
                                );
                            }
                            return false;
                        });
                    });
    
                    if (isConflict) {
                        isOverlap = true;
                    }
    
                    isGridEdited = true;
    
                    return {
                        ...programInfo,
                        startAt: updatedStartAt,
                        endAt: updatedEndAt,
                    };
                }
                return scheduleItem;
            });
    
            return {
                ...grid,
                scheduleItems: updatedScheduleItems,
                isEdit: isGridEdited ? true : grid.isEdit
            };
        });
    
        // If overlap detected, show error and exit early
        if (isOverlap) {
            toast.error("Program cannot overlap with an existing program.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
            });
            setPanelHoverTime(null);
            setPanelLinePosition(null);
            setPanelHoverDate(null);
            return;
        }
    
        // Sort scheduleItems by startAt and update sequence numbers
        updatedGridList = updatedGridList.map((grid) => {
            const sortedScheduleItems = grid.scheduleItems.sort((a, b) => a.startAt - b.startAt);
            return {
                ...grid,
                scheduleItems: sortedScheduleItems.map((item, index) => ({
                    ...item,
                    sequence: index + 1,
                })),
            };
        });
        // Update state
        // setGridList(updatedGridList);
        const updatedMatchingSchedule = updatedGridList.find(
            item => item.date === panelDate
        );
        const matchedAsset = updatedMatchingSchedule?.scheduleItems.find(
            item => item.sequence === sequenceId
        );
        if (matchedAsset) {
            // setAssetInfo({ ...matchedAsset, panelDate: panelDate });
            setProgramInfo({ ...matchedAsset, panelDate: panelDate });
        }
    }
    
    const selectedFiller = (id, adSqId, sequenceId,) => {
        const matchedFiller = fillerList?.find(item => item.id === Number(id));
        let isGridEdited = false; 
        let updatedProgramInfo = { ...programInfo }; // Object ka clone banao
    
        if (updatedProgramInfo.sequence === sequenceId) {
            updatedProgramInfo.adBreaks = updatedProgramInfo.adBreaks.map(ad => {
                if (ad.adSqId === adSqId) {
                    const assetId = matchedFiller["id"];
                    const duration = matchedFiller["duration"];
                    const title = matchedFiller["filename"];
                    const assetType = "filler";
                    const date = null;
                    
                    return {
                        ...ad,
                        assetId,
                        duration,
                        title,
                        assetType,
                        date,
                    };
                }
                return ad;
            });
        }

        // Calculate total duration from adBreaks
        const totalAdBreakDuration = updatedProgramInfo?.adBreaks?.reduce((sum, ad) => sum + (ad.duration || 0), 0) || 0;

        // Sort the adBreaks based on startOffsetInMillis or startAt and reassign adSqId
        let totalDuration = 0;
        let updatedAdBreaks = updatedProgramInfo?.adBreaks
        ?.sort((a, b) => (a.startOffsetInMillis || 0) - (b.startOffsetInMillis || 0))
        .map((ad, index) => ({
            ...ad,
            adSqId: index + 1, // Assign incremental adSqId
        }));

        updatedAdBreaks = updatedAdBreaks.map((ad, index) => {
        totalDuration += index === 0 ? 0 : updatedAdBreaks[index - 1]?.duration || 0;
        const startOffsetInMillis = ad.adInsertionPoint + totalDuration;

        return {
            ...ad,
            startOffsetInMillis,
            startAt: millisecondsToHuman(startOffsetInMillis),
        };
        });

        // Update program with adjusted totalDuration and endAt
        const updatedScheduleItem = {
        ...updatedProgramInfo,
        adBreaks: updatedAdBreaks,
        totalDuration: (updatedProgramInfo?.duration || 0) + totalAdBreakDuration,
        endAt: (updatedProgramInfo?.startAt || 0) + (updatedProgramInfo?.duration || 0) + totalAdBreakDuration, // Adjust endAt based on startAt
        };
    
        setProgramInfo(updatedScheduleItem);
    };

    const setAdsTime = (e, adSqId, sequenceId) => {
        let convertTime = verifyMilliSeconds(e);
        let isGridEdited = false; 

        let schedule = { ...programInfo }; // Clone object
    
        if (schedule.sequence === sequenceId) {
            let totalDuration = 0;
    
            let updatedAdBreaks = schedule.adBreaks.map(ad => {
                if (schedule.duration < convertTime) {
                    toast.error("Ads cannot be set beyond the video's duration.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 3000
                    });
                    return ad;
                }
    
                const isOverlapping = schedule.adBreaks.some(
                    a => a.adInsertionPoint === convertTime && a.adSqId !== ad.adSqId
                );
    
                if (isOverlapping) {
                    toast.error("Ad cannot overlap with an existing ad.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 3000
                    });
                    return ad;
                }
    
                if (ad.adSqId === adSqId) {
                    isGridEdited = true;
                    return {
                        ...ad,
                        adInsertionPoint: convertTime,
                        adInsertionPointFormat: e
                    };
                }
    
                return ad;
            });
    
            // **Sort & Reassign `adSqId`**
            updatedAdBreaks = updatedAdBreaks
                .sort((a, b) => a.adInsertionPoint - b.adInsertionPoint)
                .map((ad, index) => ({ ...ad, adSqId: index + 1 }));
    
            // **Recalculate Offsets**
            totalDuration = 0;
            updatedAdBreaks = updatedAdBreaks.map((ad, index) => {
                if (index > 0) {
                    totalDuration += updatedAdBreaks[index - 1]?.duration || 0;
                }
                const startOffsetInMillis = ad.adInsertionPoint + totalDuration;
    
                return {
                    ...ad,
                    startOffsetInMillis,
                    startAt: millisecondsToHuman(startOffsetInMillis),
                };
            });
    
            schedule = { ...schedule, adBreaks: updatedAdBreaks };
        }
        
        setProgramInfo(schedule);
    };

    const deleteEvent = (seqId) => {
        let isGridEdited = false;
        let updatedScheduleItems = {
            ...programInfo
        }
        // Filter and update adBreaks
        const updatedAdBreaks = updatedScheduleItems.adBreaks
            ?.filter(ad => ad.adSqId !== seqId) // Remove the ad with matching adSqId
            .map((ad, index) => ({
                ...ad,
                adSqId: index + 1 // Rearrange adSqId
            })) || [];

        // If adBreaks updated, mark grid as edited
        if (updatedAdBreaks.length !== (updatedScheduleItems.adBreaks?.length || 0)) {
            isGridEdited = true;
        }

        // Recalculate total duration, startAt, and endAt
        let totalAdBreakDuration = updatedAdBreaks.reduce((sum, ad) => sum + (ad.duration || 0), 0);
        const totalDuration = totalAdBreakDuration + (updatedScheduleItems?.duration || 0);
        const endTime = updatedScheduleItems?.startAt + (updatedScheduleItems?.duration || 0) + totalAdBreakDuration;
        const adBreaksCount = updatedAdBreaks.length;

        // Update the object
        updatedScheduleItems.adBreaks = updatedAdBreaks;
        updatedScheduleItems.totalDuration = totalDuration;
        updatedScheduleItems.adBreaksCount = adBreaksCount;
        updatedScheduleItems.endAt = endTime;
    
        setProgramInfo(updatedScheduleItems);
    };

    const addAdsOnClick = async() => { 
        let value = locationState?.state?.defaultFiller;

        // const assetValidate = await adValidate(value["id"]);
        //     if (!assetValidate?.status) {
        //         toast.error(assetValidate?.message, {
        //             position: toast.POSITION.BOTTOM_RIGHT,
        //             autoClose: 3000
        //         });
        //         return;
        //     } 
    
        // Function to check if the new ad break overlaps with existing ad breaks
        function isOverlapping(adBreaks, newAd) {
            return adBreaks.some(ad => {
                const adStart = ad.startOffsetInMillis;
                const adEnd = ad.startOffsetInMillis + ad.duration;
                const newAdStart = newAd.startOffsetInMillis;
                const newAdEnd = newAd.startOffsetInMillis + newAd.duration;
    
                const isOverlap = 
                    (newAdStart >= adStart && newAdStart < adEnd) || 
                    (newAdEnd > adStart && newAdEnd <= adEnd) || 
                    (newAdStart <= adStart && newAdEnd >= adEnd);
    
                if (isOverlap) {
                    toast.error("New Ad cannot overlap with an existing ad.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 3000,
                    });
                }
                return isOverlap;
            });
        }

        let isGridEdited = false;
        const lastAdBreak = programInfo.adBreaks[programInfo.adBreaks.length - 1];   
        const lastStartOffset = lastAdBreak ? lastAdBreak?.startOffsetInMillis + lastAdBreak?.duration: 0;
        const newStartOffset = lastStartOffset; // Adjust as needed for increment
        const totalAdBreakDurations = programInfo?.adBreaks?.reduce((sum, ad) => sum + (ad.duration || 0), 0);
        const NewhoverTime = newStartOffset + verifyMilliSeconds("00:00:30") - totalAdBreakDurations;
        
        let obj = {
            adSqId: lastAdBreak?.adSqId + 1,
            assetId: value["id"],
            type: "filler",
            adInsertionPoint: NewhoverTime,
            adInsertionPointFormat: millisecondsToHuman(NewhoverTime),
            duration: value["duration"],
            startOffsetInMillis: newStartOffset,
            title: value["filename"],
            date: null,
        };    
        const isValid = !isOverlapping(programInfo.adBreaks, obj);
        if (isValid) {
            isGridEdited = true; // Mark grid as edited
        }

        let updatedProgramInfo = {
            ...programInfo, 
            adBreaks: isValid ? [...programInfo.adBreaks, obj] : programInfo.adBreaks
        }
        
        // Calculate total duration from adBreaks
        const totalAdBreakDuration = updatedProgramInfo?.adBreaks?.reduce((sum, ad) => sum + (ad.duration || 0), 0) || 0;

        // Sort the adBreaks based on startOffsetInMillis or startAt and reassign adSqId
        let totalDuration = 0;
        let updatedAdBreaks = updatedProgramInfo?.adBreaks
        ?.sort((a, b) => (a.startOffsetInMillis || 0) - (b.startOffsetInMillis || 0))
        .map((ad, index) => ({
            ...ad,
            adSqId: index + 1, // Assign incremental adSqId
        }));

        updatedAdBreaks = updatedAdBreaks.map((ad, index) => {
        totalDuration += index === 0 ? 0 : updatedAdBreaks[index - 1]?.duration || 0;
        const startOffsetInMillis = ad.adInsertionPoint + totalDuration;

        return {
            ...ad,
            startOffsetInMillis,
            startAt: millisecondsToHuman(startOffsetInMillis),
        };
        });

        // Update program with adjusted totalDuration and endAt
        const updatedScheduleItem = {
        ...updatedProgramInfo,
        adBreaks: updatedAdBreaks,
        totalDuration: (updatedProgramInfo?.duration || 0) + totalAdBreakDuration,
        endAt: (updatedProgramInfo?.startAt || 0) + (updatedProgramInfo?.duration || 0) + totalAdBreakDuration, // Adjust endAt based on startAt
        };

        setProgramInfo(updatedScheduleItem);
    };

    return (
        <Modal show={show} className={styles.partnermodal} onHide={handleClose}>
            <Modal.Header closeButton className='p-2'>
                <Modal.Title>{programInfo?.title} | Schedule: {programInfo?.scheduledDate} at {formatTime(programInfo?.startAt)} | Duration: {millisecondsToHuman(programInfo?.totalDuration)}</Modal.Title>
                <span className="mt-1 ads-table-btn px-3 d-flex text-decoration-underline" onClick={() => setEditDisable((prev) => !prev)}>Edit <img className="ms-1" src={editoutline} alt="" style={{ filter: "invert(40%) sepia(80%) saturate(500%) hue-rotate(320deg)" }} width={18} />
                </span>
            </Modal.Header>
            <div className="right-side-content col-12">
                <div className="background-right-container rounded" style={{height: "600px"}}>
                    <div className={`top-content-right ${appTheme === "light"? "rounded shadow-sm": ""}`}>
                        {/* <div className="left-side">
                            <h5 className="py-3 px-3">{assetInfo?.title}</h5>
                        </div> */}
                        <div className="asset-duration-cont row px-1 py-2 align-items-center">
                            <div className="col-5 start-end-time">
                                <h5 className="px-2 d-flex mb-0 align-items-center">{
                                programInfo?
                                <>
                                    <TimePicker
                                        value={formatTime(programInfo?.startAt)}
                                        hourPlaceholder="HH"
                                        minutePlaceholder="mm"
                                        secondPlaceholder="ss"
                                        maxDetail="second"
                                        format="HH:mm:ss"
                                        disableClock={true}
                                        disabled={editDisable}
                                        onChange={(e) => {
                                            setProgramTime(e, programInfo?.uuid, programInfo?.sequence, programInfo?.panelDate);
                                        }}
                                    />
                                    -
                                    <TimePicker
                                        value={formatTime(programInfo?.endAt)}
                                        hourPlaceholder="HH"
                                        minutePlaceholder="mm"
                                        secondPlaceholder="ss"
                                        maxDetail="second"
                                        format="HH:mm:ss"
                                        disableClock={true}
                                        disabled={true}
                                        style={{background: "#000"}}
                                    />
                                </>
                                :
                                "00:00:00 - 00:00:00"
                                }</h5>
                            </div>
                            <div className="col-7 d-flex video-ads-time">
                                <h5 className="mx-2 mb-0"><span>Video</span> - {programInfo? millisecondsToHuman(programInfo?.duration) : "00:00:00"}</h5>
                                {
                                    categoryTab === "Ads" ?
                                        <h5 className="mx-2 mb-0"><span>Ads</span> - 
                                            {programInfo?
                                                millisecondsToHuman(programInfo?.adBreaks
                                                    ?.filter(data => data?.type === "ad" || data?.type === "filler")
                                                    ?.reduce((total, ad) => total + ad.duration, 0)
                                                )
                                                :
                                                "00:00:00"
                                            }
                                        </h5>
                                    :
                                    categoryTab === "Promos" ?
                                        <h5 className="mx-2 mb-0"><span>Promos</span> - 
                                            {programInfo?
                                                millisecondsToHuman(programInfo?.adBreaks
                                                    ?.filter(data => data?.assetType === "PROMO")
                                                    ?.reduce((total, ad) => total + ad.durationInMillis, 0)
                                                )
                                                :
                                                "00:00:00"
                                            }
                                        </h5>
                                    :
                                    categoryTab === "Secondary" ? 
                                        <h5 className="mx-2 mb-0"><span>Sec</span> - 
                                            {programInfo?
                                                millisecondsToHuman(programInfo?.adBreaks
                                                    ?.filter(data => data?.assetType === "FILLER")
                                                    ?.reduce((total, ad) => total + ad.durationInMillis, 0)
                                                )
                                                :
                                                "00:00:00"
                                            }
                                        </h5>
                                    :
                                    ''
                                }
                            </div>
                        </div>
                    </div>
                    <div className={`bottom-content-right ${appTheme === "light"? "rounded shadow-sm": ""}`}>
                        <div className="tabs mt-2 p-2">
                            <ul className={`channel-category-tab px-1 ${appTheme === "light" ? "border shadow-sm": ""}`}>
                                <li className={categoryTab === "Ads" ? "active" : ""}>
                                <a onClick={() => handlecategoryTab("Ads")}>Ads</a>
                                </li>
                                <li className={categoryTab === "Promos" ? "active" : ""}>
                                <a onClick={() => handlecategoryTab("Promos")}>Promos</a>
                                </li>
                                <li className={categoryTab === "Secondary" ? "active" : ""}>
                                <a disable onClick={() => {handlecategoryTab("Secondary");}}>Secondary</a>
                                </li>
                            </ul>
                        </div>
                        <div className={`ads-table mx-2 my-2 p-2 rounded ${appTheme === "light"? "shadow-sm border" : ""}`}>
                            <table className={`table ${appTheme === "light"? "border table-striped table-hover" : ""}`}>
                                <thead>
                                    <tr>
                                        <th>Name <span className="header-icon"></span></th>
                                        <th>Start Time <span className="header-icon"></span></th>
                                        <th>Duration</th>
                                        <th>
                                            {
                                                permission === "edit" &&
                                                "Actions"
                                            }
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { categoryTab === "Ads"?
                                        programInfo?.adBreaks?.some((data) => data?.type === "ad" || data?.type === "filler")?
                                        programInfo?.adBreaks
                                            ?.filter((data) => data?.type === "ad" || data?.type === "filler")
                                            .map((data, index) => {
                                                return (
                                                    <tr key={data.id}>
                                                        <td style={{width:"40%"}}>   
                                                            <select value={data?.assetId} onChange={(e)=> {selectedFiller(e.target.value, data?.adSqId, programInfo?.sequence, programInfo?.panelDate)}} disabled={editDisable} style={{ color: `${editDisable? "#6D6D6D": ""}` }}>
                                                                {
                                                                    fillerList?.map((fillers) => {
                                                                        return <option value={fillers?.id} >{fillers?.filename}</option>
                                                                    })
                                                                }
                                                            </select>
                                                        </td>
                                                        <td style={{width:"25%"}}>
                                                            <TimePicker
                                                                value={millisecondsToHuman(data?.adInsertionPoint)}
                                                                hourPlaceholder="HH"
                                                                minutePlaceholder="mm"
                                                                secondPlaceholder="ss"
                                                                maxDetail="second"
                                                                format="HH:mm:ss"
                                                                disableClock={true}
                                                                disabled={editDisable}
                                                                onChange={(e) => {
                                                                    setAdsTime(e, data?.adSqId, programInfo?.sequence, programInfo?.panelDate);
                                                                }}
                                                            />
                                                        </td>
                                                        <td style={{width:"25%"}}>
                                                            <div className="ads-duration">
                                                                {millisecondsToHuman(data?.duration)}
                                                            </div>
                                                        </td>
                                                        <td style={{width:"10%"}}>
                                                            {
                                                                permission === "edit" &&
                                                                <div className="table-action-group">
                                                                    <div className='icon-group'>
                                                                        <span className="table-icon" onClick={()=> {deleteEvent(data?.adSqId, programInfo?.sequence, programInfo?.panelDate)}}><img src={deleteIcon} /></span>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                            :
                                            <td colspan="4" class="text-center no-data" style={{height: "210px"}}>
                                                No ads to display.
                                            </td>
                                        :
                                        categoryTab === "Promos"?
                                        programInfo?.adBreaks?.some((data) => data?.assetType === "PROMO")?
                                        programInfo?.adBreaks
                                            ?.filter((data) => data?.assetType === "PROMO")
                                            .map((data) => {
                                                return (
                                                    <tr key={data.id}>
                                                        <td>{data?.title}</td>
                                                        <td>
                                                            <TimePicker
                                                                value={millisecondsToHuman(data?.startOffsetInMillis)}
                                                                hourPlaceholder="HH"
                                                                minutePlaceholder="mm"
                                                                secondPlaceholder="ss"
                                                                maxDetail="second"
                                                                format="HH:mm:ss"
                                                                disableClock={true}
                                                                disabled={editDisable}
                                                                onChange={(e) => {
                                                                    setAdsTime(e, data?.adSqId, programInfo?.sequence, programInfo?.panelDate);
                                                                }}
                                                            />
                                                        </td>
                                                        <td>
                                                            {millisecondsToHuman(data?.durationInMillis)}
                                                        </td>
                                                        <td>
                                                            {
                                                                permission === "edit" &&
                                                                <div className="table-action-group">
                                                                    <div className='icon-group'>
                                                                        <span className="table-icon ms-3" onClick={()=> {deleteEvent(data?.adSqId, programInfo?.sequence)}}><img src={deleteIcon} /></span>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                            :
                                            <td colspan="4" class="text-center no-data" style={{height: "210px"}}>
                                                No promos to display.
                                            </td>
                                        :
                                        categoryTab === "Secondary"?
                                        <td colspan="4" class="text-center no-data" style={{height: "210px"}}>
                                            No Secondary to display.
                                        </td>
                                        :
                                        ''
                                    }
                                </tbody>
                            </table>
                        </div>
                        {
                            categoryTab === "Ads"?
                            <span className="mt-3 ads-table-btn p-2" onClick={() => {!editDisable && addAdsOnClick(programInfo?.sequence, programInfo?.panelDate)}}>+ Ad Break</span>
                            :
                            ''
                        }
                    </div>
                    <div className={`bottom-container-buttons`}>
                        <div className="buttons-container p-2 d-flex justify-content-between">
                            <a 
                                className={`btn btn-secondary w-50 mx-3 ${appTheme === "dark"? 'text-white': ''}`} 
                                onClick={(e) => {programDelete(e, programInfo?.uuid, programInfo?.panelDate)}}
                            >Delete</a>
                            
                            <button  
                                className={`btn btn-secondary w-50 mx-3 ${appTheme === "dark"? 'text-white': ''}`} 
                                onClick={(e) => {updateModalData(programInfo)}}
                                disabled={editDisable}
                            >Update</button>
                            <a 
                                className="btn btn-danger w-50 mx-3"
                                onClick={(e) => { 
                                    e.preventDefault();
                                    if ((programInfo?.sequence - 1) > 0) {
                                        toGetAssetInfo(programInfo?.sequence - 1, programInfo?.panelDate);
                                        document
                                        .getElementById(`sequenceId${programInfo?.sequence - 1}`)
                                        ?.scrollIntoView({ behavior: "smooth" }); // Scroll to target section
                                    }
                                }}
                            >Previous</a>
                            <a 
                                className="btn btn-primary w-50 mx-3"
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (programInfo?.sequence < programInfo?.panelLength) {
                                        toGetAssetInfo(programInfo?.sequence + 1, programInfo?.panelDate);
                                        document
                                        .getElementById(`sequenceId${programInfo?.sequence + 1}`)
                                        ?.scrollIntoView({ behavior: "smooth" }); // Scroll to target section
                                    }
                                }}
                            >Next</a>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default AdsInfo;