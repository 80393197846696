import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import "../../../assets/css/style.scss"
import "./channelSetupNew.scss";
import sortingDefault from '../../../assets/images/sorting-default.svg';
import sortingUp from '../../../assets/images/sortarrow.png';
import sortingDown from '../../../assets/images/desc.png';
import editIcon from '../../../assets/images/edit-outline.svg';
import deleteIcon from '../../../assets/images/delete_forever.png';
import video_icon from '../../../assets/images/video-icon.png';
import prevIcon from '../../../assets/images/arrow-prev.svg';
import nextIcon from '../../../assets/images/arrow-next.svg';
import { ViewChannelList, SetActiveInactiveChanel, ChannelDelete, GetScheduleList } from "../../../api/api";
import Loader from "../../../component/Loader/Loader";
import { toast, ToastContainer } from "react-toastify";
import { cloudFront_URL } from '../../../service/API_URL';
import ErrorPopup from "../../../component/popup/ErrorPopup";
import { permissionDetails } from '../../../utility/localStoageData';
import viewIcon from '../../../assets/images/carbon_view.svg';

let channelData;

function ChannelSetupNew() {
  const [channelList, setChannelList] = useState([]);
  const [showLoader, setshowLoader] = useState(true);
  const [sortColumn, setSortColumn] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [pageJump, setPageJump] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [numberOfElement, setNumberOfElement] = useState(0);
  const [errorbox, setErrorbox] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [deletedata, setDeletedata] = useState(null);
  const [btntext, setbtntext] = useState(null);
  const [eventdelete, seteventdelete] = useState(null);
  const [permission, setpermission] = useState(null);
  const [scheduleList, setScheduleList] = useState([]);
  const locationState = useLocation();
  const deletepopup = (event, id) => {

    event.stopPropagation();
    setbtntext("Delete");
    setErrorMessage("Do you want to delete ?");
    setErrorbox(true);
    seteventdelete(() => () => deleteChannel(id));

  }
  const errorboxclose = () => {
    setErrorbox(false);
    setErrorMessage("");
  }

  const getPermission = () => {
    let findEdit = permissionDetails();
    if (findEdit.includes("CHANNEL_SETUP_EDIT")) setpermission("edit");
    else if (findEdit.includes("CHANNEL_SETUP_READ")) setpermission("read");
    else setpermission(null);
  }

  useEffect(()=> {
    getPermission();
    fetchSchedule();
  },[])

  const fetchSchedule = async() => {
    let queryParams = {
      sortBy: sortColumn,
      sortOrder: sortDirection,
      pageNumber: currentPage,
      pageSize: 99999,
      daysList: 7,
    };
    let scheduleData = await GetScheduleList(queryParams);
    setScheduleList(scheduleData?.data?.content);
  }

  useEffect(() => {
    let queryParams = {
      searchText: searchText,
      sortBy: sortColumn,
      sortOrder: sortDirection,
      pageNumber: locationState?.state?.currentPage? locationState?.state?.currentPage : currentPage,
      pageSize: pageSize
    };
    getChannelList(queryParams);
  }, [currentPage]);

  const navigate = useNavigate();

  const goToCreateChannel = () => {
    navigate('/channelCreation');
  };

  const handleSorting = (column) => {
    let sorting = {
      sortColumn: column,
      sortDirection: null
    }
    setSortColumn(column);
    if (sortDirection == 'asc') {
      setSortDirection('desc');
      sorting.sortDirection = 'desc';
    } else {
      setSortDirection('asc');
      sorting.sortDirection = 'asc';
    }

    let queryParams = {
      searchText: searchText,
      sortBy: sorting.sortColumn,
      sortOrder: sorting.sortDirection,
      pageNumber: currentPage,
      pageSize: pageSize
    };
    getChannelList(queryParams);
  }

  const getChannelList = async (queryParam) => {
    const channelResponse = await ViewChannelList(setshowLoader, queryParam);
    if (channelResponse?.content && channelResponse?.content?.length) {
      let channelList = channelResponse?.content.map((item) => {
        return { ...item, activeProgress: false }
      })
      channelData = channelList;
      setChannelList(channelList);
      setCurrentPage(channelResponse?.number);
      setTotalPages(channelResponse?.totalPages);
      setNumberOfElement(channelResponse?.numberOfElements);
    }
    navigate(locationState.pathname, { state: {} });
  }

  const deleteChannel = async (param) => {
    const response = await ChannelDelete(param);
    if (response?.data?.message == "Channel deleted successfully") {
      toast.success(response.data.message, {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 1000
      });
      let queryParams = {
        searchText: "",
        sortBy: sortColumn,
        sortOrder: sortDirection,
        pageNumber: currentPage,
        pageSize: pageSize
      };
      getChannelList(queryParams);
    } else {
      toast.error("Channel Deletion failed", {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 1000
      });
    }
  }

  const handleChannelStatus = async (event, channel) => {
    const inProgressTableData = channelList.map((row) =>
      row.id === channel ? { ...row, activeProgress: true } : row
    );
    setChannelList(inProgressTableData);
    let res = await SetActiveInactiveChanel(channel, event.target.value === 'true' ? false : true);
    if (res?.status) {
      const updatedTableData = channelList.map((row) =>
        row.id === channel ? { ...row, activeProgress: false, active: event.target.value === 'true' ? false : true } : row
      );
      channelData = updatedTableData;
      setChannelList(updatedTableData);
      toast.success(res?.data?.message, {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 1000
      });
    }else {
      toast.error(res?.response?.data?.message, {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 3000
      });
      setChannelList(channelList);
    }
  }

  const handleSearch = (event) => {
    setSearchText(event);
    let queryParams = {
      searchText: event,
      sortBy: sortColumn,
      sortOrder: sortDirection,
      pageNumber: currentPage,
      pageSize: pageSize
    };
    getChannelList(queryParams);
  }

  const editChannel = (event) => {
    navigate('/channelCreation', {
      state: {
        id: event,
        permission: permission,
        currentPage: currentPage
      }
    })
  }
  const globalSearchInArray = (data, searchTerm, keys) => {
    const search = (obj) => {
      for (const key in obj) {
        const value = obj[key];
        if (typeof value === 'object' && value !== null) {
          if (search(value)) {
            return true;
          }
        } else if (Array.isArray(value)) {
          value.forEach((item) => {
            if (search(item)) {
              return true;
            }
          })
        } else if (
          typeof value === 'string' ||
          typeof value === 'number' ||
          typeof value === 'boolean'
        ) {
          if (
            String(value).toLowerCase().includes(searchTerm.toLowerCase()) && keys.includes(key)
          ) {
            return true;
          }
        }
      }
      return false;
    };
    return data.filter((item) => search(item));
  };



  const handleNext = () => {
    if (currentPage < (totalPages - 1)) {
      setCurrentPage(currentPage + 1);
    }

  }

  const handlePrev = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  }

  const setPage = (e) => {
    if (e.target.value > 0 && e.target.value <= totalPages) {
      setPageJump(e.target.value - 1);
    }
  }

  const handleKeyPress = (event) => {
    event.preventDefault();
    setCurrentPage(pageJump);
  }

  return (
    permission !== null?
    <div className="mt-4">
      <div className="">
       
       
        <div className='channel-setup-table'>
          <table className='cms-table-list'>
            <thead> 
              <tr>
                <th className='col6' onClick={() => { handleSorting('name') }}>
                
                
                </th>
                <th className='col1 ' onClick={() => { handleSorting('name') }}>
                  <label >Channel Name</label>
                  <span>
                    {sortColumn == 'name' && sortDirection == 'asc' ? <img src={sortingUp} /> : null}
                    {sortColumn == 'name' && sortDirection == 'desc' ? <img src={sortingDown} /> : null}
                    {sortColumn != 'name' ? <img src={sortingDefault} /> : null}
                  </span>
                </th>
                <th className='col2'>
                  <label>Partner Name</label>
                </th>
                <th className='col3' onClick={() => { handleSorting('type') }}>
                  <label>Channel type</label>
                  <span>
                    {sortColumn == 'type' && sortDirection == 'asc' ? <img src={sortingUp} /> : null}
                    {sortColumn == 'type' && sortDirection == 'desc' ? <img src={sortingDown} /> : null}
                    {sortColumn != 'type' ? <img src={sortingDefault} /> : null}
                  </span>
                </th>
                <th className='col4'>
                  <label>Category</label>
                </th>
                <th className='col5'>
                  <label>Action</label>
                </th>
              </tr>
            </thead>
            <tbody>
              {
                showLoader ?
                  <tr>
                    <td colSpan="5">
                      <div className='tableLoader'>
                        <Loader></Loader>
                      </div>
                    </td>
                  </tr>
                  :
                  channelList.map((item, index) => (
                    <tr key={index}>
                      <td className='col6 '> { item?.channelLogo[0]?.thumbnailSourcePath? <img className="mx-2" style={{ width: "40px", height: "40px" }} src={cloudFront_URL + item?.channelLogo[0]?.thumbnailSourcePath
                      } alt />:<img className="mx-2" style={{ width: "40px", height: "40px" }} src={video_icon}/>} </td>
                      <td className='col1 '>  {item.name}</td>
                      <td className='col2'>
                        {
                          item.partners.map((partner, index) => {
                            if (index > 0) {
                              return <span key={index}>, {partner.name}</span>
                            } else {
                              return <span key={index}>{partner.name}</span>
                            }
                          })
                        }
                      </td>
                      <td className='col3'>{item.type}</td>
                      <td className='col4'>
                        {
                          item.categories.map((category, index) => {
                            if (index > 0) {
                              return <span key={index}>, {category.name}</span>
                            } else {
                              return <span key={index}>{category.name}</span>
                            }
                          })
                        }
                      </td>
                      <td className='col5'>
                        <div className="table-action-group">
                          <div className="switch-check">
                            {item.activeProgress ?
                              <span className='switch-loader'></span>
                              :
                              null
                            }
                            {
                              permission === "edit"?
                                <>
                                  <input
                                  type="checkbox"
                                  checked={item.active}
                                  value={item.active}
                                  onChange={(event) => handleChannelStatus(event, item.id)}
                                  id={item.id} />
                                  <label htmlFor={item.id}></label>
                                </>
                              : permission === "read"?
                                <>
                                  <input
                                  type="checkbox"
                                  checked={item.active}
                                  value={item.active}
                                  disabled
                                  id={item.id} />
                                  <label htmlFor={item.id}></label>
                                </>
                              :
                                ''
                            }
                          </div>
                          {item.active ? (
                            <label className="action-label">Enabled</label>
                          ) : (
                            <label className="action-label">Disabled</label>
                          )}
                          <div className='icon-group'>
                            <span className="table-icon" onClick={() => { editChannel(item.id) }}><img src={permission === "edit"?editIcon: permission === "read"? viewIcon :''} /></span>
                            {
                              permission === "edit"?
                              <span className="table-icon" onClick={(e) => { deletepopup(e,item.id) }}><img src={deleteIcon} /></span>
                              :
                              ''
                            }
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
              }
           
            </tbody>
          </table>

          {!showLoader && channelList.length > 0 ?
            <div className="table-pagination">
              <div className="pagination-count">
                <div className="count">
                  Page: {(currentPage + 1)} of {totalPages}
                </div>
                <div className="pagination-arrow">
                  <a href="javascript:void(0)" className={`prev ${currentPage == 0 ? 'disable' : ''}`} onClick={handlePrev}><img src={prevIcon} /></a>
                    <form onSubmit={handleKeyPress}>
                      <input 
                        type='text' 
                        defaultValue={(currentPage + 1)} 
                        onChange={setPage}
                      />
                    </form>
                  <a href="javascript:void(0)" className={`next ${currentPage == (totalPages - 1) ? 'disable' : ''}`} onClick={handleNext}><img src={nextIcon} /></a>
                </div>
              </div>
              <div>
              </div>
            </div>
            :
            null
          }
        </div>
      </div>
      <ToastContainer />
      
      {errorbox && (
              <ErrorPopup
                btntext={btntext}
                message={errorMessage}
                closebox={() => errorboxclose()}
                overrides={() => eventdelete()}
              />
            )}
    </div>
    :
    ''
  )
}

export default ChannelSetupNew;