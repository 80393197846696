import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


const DatePickerCell = ({ row, column, updateRow }) => {
  const initialValue = row.original[column.id] ? new Date(row.original[column.id]) : null;
  const [selectedDate, setSelectedDate] = useState(initialValue);

  const handleChange = (date) => {
    setSelectedDate(date);
    const formattedDate = date ? date.toISOString().split('T')[0] : '';
    updateRow(row.index, column.id, formattedDate);
  };

  return (
    <DatePicker
      selected={selectedDate}
      onChange={handleChange}
      dateFormat="yyyy-MM-dd"
      placeholderText="Select Date"
      className="custom-datepicker"

    />
  );
};

export default DatePickerCell;
