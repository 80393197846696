
import React, { useState, useEffect } from 'react';

const EditableTextCell = ({ row, column, value, updateRow }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(value || '');
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    setInputValue(value || '');
  }, [value]);

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleBlur = () => {
    setIsEditing(false);
    updateRow(row.index, column.id, inputValue);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      setIsEditing(false);
      updateRow(row.index, column.id, inputValue);
    }
  };

  return isEditing ? (
    <input
      type="text"
      value={inputValue}
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      autoFocus
      style={{
        background: '#000',
        color: '#fff',
        width: '100%',
        border: '0.5px solid #999999',
        padding: '2px',
        boxSizing: 'border-box',
        fontSize: 'inherit',
        fontFamily: 'inherit',
      }}
    />
  ) : (
    <div
      style={{
        position: 'relative',
        width: '100%',
        padding: '4px',
        boxSizing: 'border-box',
        cursor: 'pointer',
      }}
      onClick={() => setIsEditing(true)}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      <div
        style={{
          color: '#fff',
          display: '-webkit-box',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {value || ''}
      </div>

      {/* Tooltip */}
      {showTooltip && value && (
        <div
          style={{
            position: 'absolute',
            backgroundColor: '#333',
            color: '#fff',
            padding: '6px 8px',
            borderRadius: '4px',
            zIndex: 1000,
            top: '100%',
            left: 0,
            whiteSpace: 'normal',
            maxWidth: '300px',
            minWidth: '150px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
            fontSize: '14px',
            lineHeight: '1.4',
          }}
        >
          {value}
        </div>
      )}
    </div>
  );
};

export default EditableTextCell;
