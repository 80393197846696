import React, { useState } from "react";
import "./DistributionPlatform.scss";
import DistributionTable from "../DistributionTable";

const DistributionPlatform = () => {
  const tableData = [
    {
      name: "Channel A",
      activeUsers: 1000,
      watchHours: 5000,
      avgWatchTimePerUser: "00:30:00",
      avgWatchTimePerSession: "00:15:00",
      adImpressions: 2000,
      children: [
        { name: "Sub-Channel A1", activeUsers: 500, watchHours: 2500 },
        { name: "Sub-Channel A2", activeUsers: 500, watchHours: 2500 },
      ],
    },
    {
      name: "Channel B",
      activeUsers: 800,
      watchHours: 4000,
      avgWatchTimePerUser: "00:25:00",
      avgWatchTimePerSession: "00:10:00",
      adImpressions: 1500,
      children: [
        { name: "Sub-Channel B1", activeUsers: 400, watchHours: 2000 },
        { name: "Sub-Channel B2", activeUsers: 400, watchHours: 2000 },
      ],
    },
  ];

  return (
    <div className="distribution-platform">
      <DistributionTable data={tableData} />
    </div>
  );
};

export default DistributionPlatform;