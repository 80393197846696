import React, { useState, useEffect } from "react";
import "./AddDistributionPlatform.scss";
import Select from "react-select";
import DeleteIcon from '../../assets/svg/Distribution/delete_forever.svg';
import EditIcon from '../../assets/svg/Distribution/add_photo_alternate.svg';
import EditChannelPopup from "./EditChannelPopup";
import { useSelector } from "react-redux";

const AddDistributionPlatform = () => {
    const [apiKey, setApiKey] = useState(false);
    const [distributionCdn, setDistributionCdn] = useState("Runn");
    const [activeTab, setActiveTab] = useState("Global URL");
    const [selectedTerritories, setSelectedTerritories] = useState([]);
    const [channels, setChannels] = useState([]);
    const [editChannel, setEditChannel] = useState(null);
    const [distributionActiveTab, setDistributionActiveTab] = useState("Global URL");
    const [adTagActiveTab, setAdTagActiveTab] = useState("Global URL");
    const state = useSelector((state) => state);
    const Territorys = state?.CountryList?.data?.data || [];
    const [territoryOption, setTerritoryOption] = useState([]);
    const [platformName, setPlatformName] = useState("");
    const [platformCode, setPlatformCode] = useState("");
    const [distributionUrl, setDistributionUrl] = useState("");
    const [adTagUrl, setAdTagUrl] = useState("");
    const mappedTerritoryOptions = Territorys.map((item) => ({
        value: item.iso,
        label: item.niceName
    }));

    const distributionPlaceholder = distributionActiveTab === "Global URL" ? "Enter Global URL" : "Enter Unique URL";
const adTagPlaceholder = adTagActiveTab === "Global URL" ? "Enter Global URL" : "Enter Unique URL";

    const addChannel = () => {
        if (!platformName || !platformCode || !distributionUrl || !adTagUrl || selectedTerritories.length === 0) {
            alert("Please fill all required fields before adding a channel.");
            return;
        }
        const newChannel = {
            id: channels.length + 1,
            name: `Channel ${channels.length + 1}`,
            distributionUrl: distributionUrl,
            adTagUrl: adTagUrl,
            ssaiUrl: "",
            media: "",
        };
        setChannels([...channels, newChannel]);
    };


    const updateChannelData = (id, field, value) => {
        const updatedChannels = channels.map(channel =>
            channel.id === id ? { ...channel, [field]: value } : channel
        );
        setChannels(updatedChannels);
    };

    const deleteChannel = (id) => {
        const filteredChannels = channels.filter(channel => channel.id !== id);
        setChannels(filteredChannels);
    };

    const updatePlatform = () => {
        console.log("Platform Updated:", { apiKey, distributionCdn, activeTab, selectedTerritories, channels });
    };



    const resetForm = () => {
        setApiKey(false);
        setDistributionCdn("Runn");
        setActiveTab("Global URL");
        setSelectedTerritories([]);
        setChannels([]);
        setPlatformName("");
        setPlatformCode("");
        setDistributionUrl("");
        setAdTagUrl("");
    };

    const channelTypeList = [
        { type: "ABP NEWS" },
        { type: "Cineshorts Premiere" },
        { type: "Comedy Pit Stop" },
        { type: "INDIA TV" },
        { type: "Navbharat" },
        { type: "NDTV India" },
        { type: "Cineshorts Film" },
    ];
    const customStyles = {
        control: (base, state) => ({
            ...base,
            backgroundColor: "#222", // Dark background
            borderColor: state.isFocused ? "#888" : "#555",
            color: "#fff",
            "&:hover": {
                borderColor: "#999"
            }
        }),
        menu: (base) => ({
            ...base,
            backgroundColor: "#222", // Dropdown background
            color: "#fff"
        }),
        menuList: (base) => ({
            ...base,
            backgroundColor: "#222" // Dark dropdown list
        }),
        option: (base, { isFocused, isSelected }) => ({
            ...base,
            backgroundColor: isSelected ? "#444" : isFocused ? "#555" : "#222",
            color: "#fff",
            "&:hover": {
                backgroundColor: "#666"
            }
        }),
        multiValue: (base) => ({
            ...base,
            backgroundColor: "#555", // Selected items background
            color: "#fff"
        }),
        multiValueLabel: (base) => ({
            ...base,
            color: "#fff"
        }),
        multiValueRemove: (base) => ({
            ...base,
            color: "#fff",
            "&:hover": {
                backgroundColor: "#777",
                color: "#000"
            }
        })
    };

    const availableChannels = [
        { id: 1, name: "ABP NEWS" },
        { id: 2, name: "Cineshorts Premiere" },
        { id: 3, name: "Comedy Pit Stop" },
        { id: 4, name: "INDIA TV" }
    ];
    useEffect(() => {
        if (Territorys.length > 0) {
            let countries = Territorys.map((item) => ({
                territoryId: item.iso,
                territoryName: item.niceName
            }));

            countries.sort((a, b) => a.territoryName.localeCompare(b.territoryName));
            setTerritoryOption(countries);
        }
    }, [Territorys]);


    return (
        <div className="content-body">
            <div className="distributionPlatform">
                <h2 className="title">Add New Distribution Platform</h2>
                <div className="formContainer">
                    <div className="formRow">
                        <div className="formGroup">
                            <label>Platform Name <span className="required">*</span></label>
                            <input type="text" placeholder="Enter name"
                                value={platformName}
                                onChange={(e) => setPlatformName(e.target.value)} />
                        </div>

                        <div className="formGroup">
                            <label>Platform Code <span className="required">*</span></label>
                            <input type="text" placeholder="Enter Code"
                                value={platformCode}
                                onChange={(e) => setPlatformCode(e.target.value)} />
                        </div>
                    </div>
                    <div className="formRow mt-4">
                        <div className="formGroup">
                            <div className="row-direction">
                                <label className="distributionLabel">Distribution URL <span className="required">*</span></label>
                                <div className="tabContainer">
                                    <button
                                        className={distributionActiveTab === "Global URL" ? "active" : ""}
                                        onClick={() => setDistributionActiveTab("Global URL")}
                                    >
                                        Global
                                    </button>
                                    <button
                                        className={distributionActiveTab === "Unique URL" ? "active" : ""}
                                        onClick={() => setDistributionActiveTab("Unique URL")}
                                    >
                                        Unique
                                    </button>
                                </div>
                            </div>

                            <input type="text" placeholder={distributionPlaceholder} className="distributionInput"
                                value={distributionUrl}
                                onChange={(e) => setDistributionUrl(e.target.value)} />
                        </div>
                        <div className="formGroup">
                            <div className="row-direction">
                                <label className="distributionLabel">Ad Tag URL <span className="required">*</span></label>
                                <div className="tabContainer">
                                    <button
                                        className={adTagActiveTab === "Global URL" ? "active" : ""}
                                        onClick={() => setAdTagActiveTab("Global URL")}
                                    >
                                        Global
                                    </button>
                                    <button
                                        className={adTagActiveTab === "Unique URL" ? "active" : ""}
                                        onClick={() => setAdTagActiveTab("Unique URL")}
                                    >
                                        Unique
                                    </button>
                                </div>
                            </div>
                            <input type="text" placeholder={adTagPlaceholder} className="distributionInput"
                                value={adTagUrl}
                                onChange={(e) => setAdTagUrl(e.target.value)} />
                        </div>

                    </div>
                    <div className="formRow mt-4">
                        <div className="formGroup">
                            <label className="formLabel">Territory <span className="required">*</span></label>
                            <Select
                                isMulti
                                options={mappedTerritoryOptions}
                                value={selectedTerritories}
                                onChange={setSelectedTerritories}
                                className="multiSelectOption"
                                styles={customStyles}
                            />
                        </div>
                        <div className="formGroup">
                            <div className="radioApiOption">
                                <div >
                                    <label className="formLabel mb-2">
                                        API Key <span className="required">*</span>
                                    </label>
                                    <div className="flexOption">
                                        <div className="toggleSwitch" >
                                            <input
                                                type="checkbox"
                                                id="apiKeyToggle"
                                                checked={apiKey}
                                                onChange={() => setApiKey(!apiKey)}

                                            />
                                            <label htmlFor="apiKeyToggle"></label>

                                        </div>
                                        <div>
                                            <span>{apiKey ? "Yes" : "No"}</span>
                                        </div>
                                    </div>


                                </div>
                                <div style={{ marginRight: "100px" }}>
                                    <label className="formLabel mb-2">Distribution CDN</label>
                                    <div style={{ display: "flex", alignItems: "center", gap: "30px" }}>
                                        <input
                                            type="radio"
                                            name="cdn"
                                            value="Runn"
                                            checked={distributionCdn === "Runn"}
                                            onChange={() => setDistributionCdn("Runn")}
                                        />
                                        <label className={`radioOption ${distributionCdn === "Runn" ? "active" : ""}`}>
                                            Runn
                                        </label>

                                        <input
                                            type="radio"
                                            name="cdn"
                                            value="Others"
                                            checked={distributionCdn === "Others"}
                                            onChange={() => setDistributionCdn("Others")}
                                        />
                                        <label className={`radioOption ${distributionCdn === "Others" ? "active" : ""}`}>
                                            Others
                                        </label>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="channelList">
                        <label className="channelLable">Channel List</label>
                        <button className="addChannelBtn" onClick={addChannel}>Add Channel</button>
                    </div>

                    {channels.length > 0 && (
                        <table className="channelTable">
                            <thead>
                                <tr>
                                    <th style={{ backgroundColor: "#393939" }}>Sr.NO</th>
                                    <th style={{ backgroundColor: "#393939" }}>CHANNEL NAME</th>
                                    <th style={{ backgroundColor: "#393939" }}>DISTRIBUTION URL</th>
                                    <th style={{ backgroundColor: "#393939" }}>Ad Tag URL</th>
                                    <th style={{ backgroundColor: "#393939" }}>SSAI URL</th>
                                    <th style={{ backgroundColor: "#393939" }}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {channels.map((channel, index) => (
                                    <tr key={channel.id}>
                                        <td>{index + 1}</td>
                                        <td>
                                            <select
                                                value={channel.name}
                                                onChange={(e) => updateChannelData(channel.id, "name", e.target.value)}
                                            >
                                                <option value="">Select Channel</option>
                                                {availableChannels.map((ch) => (
                                                    <option key={ch.id} value={ch.name}>
                                                        {ch.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                placeholder="Enter URL"
                                                value={channel.distributionUrl}
                                                onChange={(e) => updateChannelData(channel.id, "distributionUrl", e.target.value)}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                placeholder="Ad Tag URL"
                                                value={channel.adTagUrl}
                                                onChange={(e) => updateChannelData(channel.id, "adTagUrl", e.target.value)}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                placeholder="Enter SSAI URL"
                                                value={channel.ssaiUrl}
                                                onChange={(e) => updateChannelData(channel.id, "ssaiUrl", e.target.value)}
                                            />
                                        </td>
                                        <td>
                                            <div className="actionBtn">
                                                <div className="editBtn" onClick={() => setEditChannel(channel)}>
                                                    <img src={EditIcon} alt="Edit" />
                                                </div>
                                                <div className="deleteBtn" onClick={() => deleteChannel(channel.id)}>
                                                    <img src={DeleteIcon} alt="Delete" />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}

                </div>
                <div className="buttonGroup">
                    <button className="cancelBtn">Cancel</button>
                    <div className="twoButton">
                        <button className="resetBtn" onClick={resetForm}>Reset</button>
                        <button className="updateBtn" onClick={updatePlatform}>Update</button>
                    </div>
                </div>
            </div>

            {editChannel && (
                <EditChannelPopup
                    channel={editChannel}
                    updateChannelData={updateChannelData}
                    onClose={() => setEditChannel(null)}
                    channelTypeList={channelTypeList}
                    initialDistributionType={distributionActiveTab} 
                    initialAdTagType={adTagActiveTab} 
                />
            )}

        </div>
    );
};

export default AddDistributionPlatform;
