import revertIcon from "../../../../assets/images/revert.svg";

export const HeaderSection = ({ locationState, updateLoopData, loaderStatus }) => {
    return (
        <>
            <div className="left-side">
                <h3>{locationState?.state?.channelName}</h3>
            </div>
            <div className="right-side d-flex">
                <a className='btn btn-revert mx-2 p-1 bg-secondary'><img src={revertIcon} width={35}/></a>
                {
                    locationState?.state?.permission === "edit" &&
                    <button 
                        className='btn btn-primary mx-2' 
                        disabled={loaderStatus} 
                        onClick={updateLoopData}
                    >
                        {
                            loaderStatus &&
                            <div className="loader" style={{width: "20px", height: "20px", marginBottom: "0px"}}>
                            </div>
                        }
                        Update
                    </button>
                }
                <button className='btn btn-danger mx-2'>Auto Schedule</button>
            </div>
        </>
    )
}