import EditableTextCell from './EditableTextCell';
import DropdownCell from './DropdownCell';
import DatePickerCell from './DatePickerCell';
import MultiSelectCell from './MultiSelectCell';
import { TimePickerGrid } from './timepicker';

export const getColumns = ({
  AudioLanguageList,
  CountryList,
  GenreList,
  ThemesList,
  CategoriesList,
  MaturityList,
  ContentGroup,
  updateRow,
}) => [
    {
      accessorKey: 'programId',
      header: 'Prog. ID',
      size: 170,
      cell: ({ row }) => (
        <EditableTextCell
          row={row}
          column={{ id: 'programId' }}
          value={row.original.programId || ''}
          updateRow={updateRow}
          style={{
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            overflow: 'hidden',
            whiteSpace: 'normal',
            wordBreak: 'break-word',
            height: '40px',
          }}
        />
      ),
    },
    {
      accessorKey: 'title',
      header: 'TITLE',
      size: 170,
      cell: ({ row }) => (
        <EditableTextCell
          row={row}
          column={{ id: 'title' }}
          value={row.original.title || ''}
          updateRow={updateRow}
          style={{
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            overflow: 'hidden',
            whiteSpace: 'normal',
            wordBreak: 'break-word',
            height: '40px',
          }}
        />
      ),
    },
    {
      accessorKey: 'duration',
      header: 'Duration',
      size: 120,
      cell: ({ row }) => (
        <TimePickerGrid
          row={row.original}
          column={{ key: 'duration' }}
          onRowChange={(updatedRow) => updateRow(updatedRow)}
        />
      ),
    },
    {
      accessorKey: 'shortSynopsis',
      header: 'Short Synopsis',
      size: 210,
      cell: ({ row }) => (
        <EditableTextCell
          row={row}
          column={{ id: 'shortSynopsis' }}
          value={row.original.shortSynopsis || ''}
          updateRow={updateRow}
        >
          {row.original.shortSynopsis || ''}
        </EditableTextCell>
      ),
    },
    {
      accessorKey: 'actors',
      header: 'Actors',
      size: 170,
      cell: ({ row }) => (
        <EditableTextCell
          row={row}
          column={{ id: 'actors' }}
          value={row.original.actors || ''}
          updateRow={updateRow}
        />
      ),
    },
    {
      accessorKey: 'directors',
      header: 'Directors',
      size: 170,
      cell: ({ row }) => (
        <EditableTextCell
          row={row}
          column={{ id: 'directors' }}
          value={row.original.directors || ''}
          updateRow={updateRow}
        />
      ),
    },
    {
      accessorKey: 'releaseDate',
      header: 'Release Date',
      size: 135,
      cell: ({ row }) => (
        <DatePickerCell
          row={row}
          column={{ id: 'releaseDate' }}
          updateRow={updateRow}
          formatDateForInput={(val) => (val ? new Date(val).toISOString().split('T')[0] : '')}
        />
      ),
    },
    {
      accessorKey: 'originalLanguage',
      header: 'Original Language',
      cell: ({ row }) => (
        <DropdownCell
          row={row}
          field="originalLanguage"
          options={AudioLanguageList || []}
          updateRow={updateRow}
        />
      ),
    },
    {
      accessorKey: 'origin',
      header: 'Origin',
      cell: ({ row }) => (
        <DropdownCell
          row={row}
          field="origin"
          options={CountryList || []}
          updateRow={updateRow}
        />
      ),
    },
    {
      accessorKey: 'genres',
      header: 'Genre',
      size: 250,
      cell: ({ row }) => (
        <MultiSelectCell
          row={row}
          column={{ id: 'genres' }}
          options={GenreList?.data || []}
          updateRow={updateRow}
        />
      ),
    },
    {
      accessorKey: 'themes',
      header: 'Themes',
      size: 250,
      cell: ({ row }) => (
        <MultiSelectCell
          row={row}
          column={{ id: 'themes' }}
          options={ThemesList?.data || []}
          updateRow={updateRow}
        />
      ),
    },
    {
      accessorKey: 'categories',
      header: 'Categories',
      size: 250,
      cell: ({ row }) => (
        <MultiSelectCell
          row={row}
          column={{ id: 'categories' }}
          options={CategoriesList?.data || []}
          updateRow={updateRow}
        />
      ),
    },
    {
      accessorKey: 'ratings',
      header: 'Rating',
      cell: ({ row }) => (
        <DropdownCell
          row={row}
          field="ratings"
          options={MaturityList || []}
          updateRow={updateRow}
        />
      ),
    },
    {
      accessorKey: 'ratingsDescriptor',
      header: 'Ratings Descriptor',
      size: 150,
      cell: ({ row }) => (
        <EditableTextCell
          row={row}
          column={{ id: 'ratingsDescriptor' }}
          value={row.original.ratingsDescriptor || ''}
          updateRow={updateRow}
        />
      ),
    },
    {
      accessorKey: 'territories',
      header: 'Territory',
      size: 250,
      cell: ({ row }) => (
        <MultiSelectCell
          row={row}
          column={{ id: 'territories', header: 'Territory' }}
          options={CountryList?.map((country) => ({ name: country.name })) || []}
          updateRow={updateRow}
        />
      ),
    },
    {
      accessorKey: 'startDate',
      header: 'Start Date',
      size: 135,
      cell: ({ row }) => (
        <DatePickerCell
          row={row}
          column={{ id: 'startDate' }}
          updateRow={updateRow}
          formatDateForInput={(val) => (val ? new Date(val).toISOString().split('T')[0] : '')}
        />
      ),
    },
    {
      accessorKey: 'endDate',
      header: 'End Date',
      size: 135,
      cell: ({ row }) => (
        <DatePickerCell
          row={row}
          column={{ id: 'endDate' }}
          updateRow={updateRow}
          formatDateForInput={(val) => (val ? new Date(val).toISOString().split('T')[0] : '')}
        />
      ),
    },
    {
      accessorKey: 'audioLanguages',
      header: 'Audio Language',
      size: 250,
      cell: ({ row }) => (
        <MultiSelectCell
          letion row={row}
          column={{ id: 'audioLanguages', header: 'Audio Language' }}
          options={AudioLanguageList?.map((lang) => ({ name: lang.name })) || []}
          updateRow={updateRow}
        />
      ),
    },
    {
      accessorKey: 'contentGroup',
      header: 'Content Group',
      size: 250,
      cell: ({ row }) => (
        <MultiSelectCell
          row={row}
          column={{ id: 'contentGroup' }}
          options={ContentGroup.map((group) => ({ name: group }))}
          updateRow={updateRow}
        />
      ),
    },
  ];