import React, { useState } from "react";
import "./DistributionTable.scss";
import DownArrow from '../../../assets/svg/Distribution/downArrow.svg';
import RightArrow from '../../../assets/svg/Distribution/rightArrow.svg';
import EditIcon from '../../../assets/svg/Distribution/editIcon.svg';
import DeleteIcon from '../../../assets/svg/Distribution/delete_forever.svg';
import EyeIcon from '../../../assets/svg/Distribution/eyeIcon.svg';

const DistributionChildTable = () => {
  const childData = [
    { id: "01", channel: "Name of the Channel", url: "URL ID 000000", ssai: "No", media: "Yes" },
    { id: "02", channel: "Name of the Channel", url: "URL ID 000001", ssai: "Yes", media: "Overwrite" },
    { id: "03", channel: "Name of the Channel", url: "URL ID 000002", ssai: "No", media: "Yes" },
    { id: "04", channel: "Name of the Channel", url: "URL ID 000003", ssai: "Yes", media: "Overwrite" },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(2);
  
  const totalPages = Math.ceil(childData.length / itemsPerPage);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const renderPaginationButtons = () => {
    return Array.from({ length: totalPages }, (_, index) => (
      <button
        key={index + 1}
        onClick={() => handlePageChange(index + 1)}
        className={`mx-1 px-3 py-1 rounded ${
          currentPage === index + 1 ? "bg-blue-500 text-white" : "bg-gray-300 hover:bg-gray-500"
        }`}
      >
        {index + 1}
      </button>
    ));
  };

  const paginatedData = childData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  return (
    <div className="childTableContainer ">
      <table className="childTable">
        <thead>
          <tr>
            <th>S.NO</th>
            <th>CHANNEL</th>
            <th>DISTRIBUTION URL</th>
            <th>SSAI URL</th>
            <th>EXISTING MEDIA</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {paginatedData.map((row) => (
            <tr key={row.id}>
              <td>{row.id}</td>
              <td>{row.channel}</td>
              <td>
                <input className="urlInputchild" value={row.url} readOnly />
              </td>
              <td>
                <input className="urlInputchild" value={row.ssai} readOnly />
              </td>
              <td>
                <input className="urlInputchild" value={row.media} readOnly />
              </td>
              
              <td>
              <div className="icons">
              <div className="icon-btn">
                  <img src={EyeIcon} alt="View" className="icon-distribution" />
                </div>
                <div className="icon-btn">
                  <img src={EditIcon} alt="Edit" className="icon-distribution" />
                </div>
                <div className="icon-btn">
                  <img src={DeleteIcon} alt="Delete" className="icon-distribution" />
                </div>
              </div>
              </td>
               
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination pagination-table flex items-center mt-2" style={{ backgroundColor: "#363636" }}>
        <div className="items-per-page mr-4">
          <label htmlFor="itemsPerPage" className="mr-2 text-white">Items per page:</label>
          <select
            id="itemsPerPage"
            value={itemsPerPage}
            onChange={handleItemsPerPageChange}
            className="selectboxpage p-1"
            style={{ border: 'none' }}
          >
            {[2, 5, 10].map((option) => (
              <option key={option} value={option}>{option}</option>
            ))}
          </select>
        </div>

        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className={`mx-1 rounded bg-gray-300 px-3 py-1 ${
            currentPage === 1 ? "opacity-50 cursor-not-allowed" : "hover:bg-gray-500"
          }`}
        >
          <img src={RightArrow} alt="Previous" className="pagination-icon" />
        </button>

        <div className="page-buttons flex">
          {renderPaginationButtons()}
        </div>

        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className={`mx-1 rounded bg-gray-300 px-3 py-1 ${
            currentPage === totalPages ? "opacity-50 cursor-not-allowed" : "hover:bg-gray-500"
          }`}
        >
          <img src={DownArrow} alt="Next" className="pagination-icon" />
        </button>
      </div>
    </div>
  );
};

export default DistributionChildTable;
