import React, { useState } from "react";
import "./DistributionTable.scss";
import DistributionChildTable from "./DistributionChildTable";
import RightArrow from '../../../assets/svg/Distribution/rightArrow.svg';
import DownArrow from '../../../assets/svg/Distribution/downArrow.svg';
import CopyIcon from '../../../assets/svg/Distribution/solar_copy-linear.svg'
import EditIcon from '../../../assets/svg/Distribution/editIcon.svg';
import DeleteIcon from '../../../assets/svg/Distribution/delete_forever.svg';

const DistributionTable = () => {
  const [expandedRow, setExpandedRow] = useState(null);

  const toggleRow = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  const parentData = [
    { id: "01",Name:'Name of the partner', partner: "runn0ind", status: "Yes", url: "URL ID 000000" },
    { id: "02",Name:'Name of the partner', partner: "runn0ind", status: "Yes", url: "URL ID 000000" },
    { id: "03",Name:'Name of the partner', partner: "runn0ind", status: "Yes", url: "URL ID 000000" },
    { id: "04",Name:'Name of the partner', partner: "runn0ind", status: "Yes", url: "URL ID 000000" },
    { id: "05",Name:'Name of the partner', partner: "runn0ind", status: "Yes", url: "URL ID 000000" },
    { id: "06",Name:'Name of the partner', partner: "runn0ind", status: "Yes", url: "URL ID 000000" },
    { id: "06",Name:'Name of the partner', partner: "runn0ind", status: "Yes", url: "URL ID 000000" },
    { id: "06",Name:'Name of the partner', partner: "runn0ind", status: "Yes", url: "URL ID 000000" },
    { id: "07",Name:'Name of the partner', partner: "runn0ind", status: "Yes", url: "URL ID 000000" },
    { id: "08",Name:'Name of the partner', partner: "runn0ind", status: "Yes", url: "URL ID 000000" },
    { id: "08",Name:'Name of the partner', partner: "runn0ind", status: "Yes", url: "URL ID 000000" },
    { id: "08",Name:'Name of the partner', partner: "runn0ind", status: "Yes", url: "URL ID 000000" },
    { id: "08",Name:'Name of the partner', partner: "runn0ind", status: "Yes", url: "URL ID 000000" },
    { id: "08",Name:'Name of the partner', partner: "runn0ind", status: "Yes", url: "URL ID 000000" },
    { id: "08",Name:'Name of the partner', partner: "runn0ind", status: "Yes", url: "URL ID 000000" },
    { id: "08",Name:'Name of the partner', partner: "runn0ind", status: "Yes", url: "URL ID 000000" },
    { id: "08",Name:'Name of the partner', partner: "runn0ind", status: "Yes", url: "URL ID 000000" },
    { id: "08",Name:'Name of the partner', partner: "runn0ind", status: "Yes", url: "URL ID 000000" },
  ];

  return (
    <div className="distributionContainer">
  <div className="tableHeader">
  <span className="partner-name">Partner Name</span>
  <div className="divider"></div>
  <span className="partner-code">Partner Code</span>
  <div className="divider"></div>
  <span className="api-key">API Key</span>
</div>


      <div className="tableWrapper part-height ">
        {parentData.map((row, index) => (
          <div key={row.id} className="parentRow">
            <div className="rowContent" onClick={() => toggleRow(index)}>
              <span className="toggleIcon">{expandedRow === index ? <img src={DownArrow} alt="View" /> : <img src={RightArrow} alt="View" />  }</span>
              <span className="partnerName">{row.Name}</span>
              <span className="partnerName">{row.partner}</span>
              <span className="partnerValue">{row.status}</span>
              <input className="urlInput" value={row.url} readOnly />
              <div className="icons">
              <div className="icon-btn">
                  <img src={CopyIcon} alt="View" className="icon-distribution" />
                </div>
                <div className="icon-btn">
                  <img src={EditIcon} alt="Edit" className="icon-distribution" />
                </div>
                <div className="icon-btn">
                  <img src={DeleteIcon} alt="Delete" className="icon-distribution" />
                </div>
              </div>
            </div>
            {expandedRow === index && <DistributionChildTable />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DistributionTable;