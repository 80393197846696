import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import "../../assets/css/style.scss"
import "./channelSetup.scss";
import SearchBar from '../../component/search-bar/searchBar';
import { ViewChannelList, SetActiveInactiveChanel, ChannelDelete, GetScheduleList } from "../../api/api";
import { toast, ToastContainer } from "react-toastify";
import ErrorPopup from "../../component/popup/ErrorPopup";
import { permissionDetails } from '../../utility/localStoageData';
import ChannelPageButton from '../../component/Channel-Distribution/ChannelPageButton';

let channelData;

function ChannelSetup() {
  const [channelList, setChannelList] = useState([]);
  const [showLoader, setshowLoader] = useState(true);
  const [sortColumn, setSortColumn] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [pageJump, setPageJump] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [numberOfElement, setNumberOfElement] = useState(0);
  const [errorbox, setErrorbox] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [deletedata, setDeletedata] = useState(null);
  const [btntext, setbtntext] = useState(null);
  const [eventdelete, seteventdelete] = useState(null);
  const [permission, setpermission] = useState(null);
  const [scheduleList, setScheduleList] = useState([]);
  const locationState = useLocation();
  
  const errorboxclose = () => {
    setErrorbox(false);
    setErrorMessage("");
  }

  const getPermission = () => {
    let findEdit = permissionDetails();
    if (findEdit.includes("CHANNEL_SETUP_EDIT")) setpermission("edit");
    else if (findEdit.includes("CHANNEL_SETUP_READ")) setpermission("read");
    else setpermission(null);
  }

  useEffect(()=> {
    getPermission();
    fetchSchedule();
  },[])

  const fetchSchedule = async() => {
    let queryParams = {
      sortBy: sortColumn,
      sortOrder: sortDirection,
      pageNumber: currentPage,
      pageSize: 99999,
      daysList: 7,
    };
    let scheduleData = await GetScheduleList(queryParams);
    setScheduleList(scheduleData?.data?.content);
  }

  useEffect(() => {
    let queryParams = {
      searchText: searchText,
      sortBy: sortColumn,
      sortOrder: sortDirection,
      pageNumber: locationState?.state?.currentPage? locationState?.state?.currentPage : currentPage,
      pageSize: pageSize
    };
    getChannelList(queryParams);
  }, [currentPage]);

  const navigate = useNavigate();

  const goToCreateChannel = () => {
    navigate('/channelCreation');
  };

  const handleSorting = (column) => {
    let sorting = {
      sortColumn: column,
      sortDirection: null
    }
    setSortColumn(column);
    if (sortDirection == 'asc') {
      setSortDirection('desc');
      sorting.sortDirection = 'desc';
    } else {
      setSortDirection('asc');
      sorting.sortDirection = 'asc';
    }

    let queryParams = {
      searchText: searchText,
      sortBy: sorting.sortColumn,
      sortOrder: sorting.sortDirection,
      pageNumber: currentPage,
      pageSize: pageSize
    };
    getChannelList(queryParams);
  }

  const getChannelList = async (queryParam) => {
    const channelResponse = await ViewChannelList(setshowLoader, queryParam);
    if (channelResponse?.content && channelResponse?.content?.length) {
      let channelList = channelResponse?.content.map((item) => {
        return { ...item, activeProgress: false }
      })
      channelData = channelList;
      setChannelList(channelList);
      setCurrentPage(channelResponse?.number);
      setTotalPages(channelResponse?.totalPages);
      setNumberOfElement(channelResponse?.numberOfElements);
    }
    navigate(locationState.pathname, { state: {} });
  }

  const deleteChannel = async (param) => {
    const response = await ChannelDelete(param);
    if (response?.data?.message == "Channel deleted successfully") {
      toast.success(response.data.message, {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 1000
      });
      let queryParams = {
        searchText: "",
        sortBy: sortColumn,
        sortOrder: sortDirection,
        pageNumber: currentPage,
        pageSize: pageSize
      };
      getChannelList(queryParams);
    } else {
      toast.error("Channel Deletion failed", {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 1000
      });
    }
  }

  const handleChannelStatus = async (event, channel) => {
    const inProgressTableData = channelList.map((row) =>
      row.id === channel ? { ...row, activeProgress: true } : row
    );
    setChannelList(inProgressTableData);
    let res = await SetActiveInactiveChanel(channel, event.target.value === 'true' ? false : true);
    if (res?.status) {
      const updatedTableData = channelList.map((row) =>
        row.id === channel ? { ...row, activeProgress: false, active: event.target.value === 'true' ? false : true } : row
      );
      channelData = updatedTableData;
      setChannelList(updatedTableData);
      toast.success(res?.data?.message, {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 1000
      });
    }else {
      toast.error(res?.response?.data?.message, {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 3000
      });
      setChannelList(channelList);
    }
  }

  const handleSearch = (event) => {
    setSearchText(event);
    let queryParams = {
      searchText: event,
      sortBy: sortColumn,
      sortOrder: sortDirection,
      pageNumber: currentPage,
      pageSize: pageSize
    };
    getChannelList(queryParams);
  }

  const editChannel = (event) => {
    navigate('/channelCreation', {
      state: {
        id: event,
        permission: permission,
        currentPage: currentPage
      }
    })
  }
  const globalSearchInArray = (data, searchTerm, keys) => {
    const search = (obj) => {
      for (const key in obj) {
        const value = obj[key];
        if (typeof value === 'object' && value !== null) {
          if (search(value)) {
            return true;
          }
        } else if (Array.isArray(value)) {
          value.forEach((item) => {
            if (search(item)) {
              return true;
            }
          })
        } else if (
          typeof value === 'string' ||
          typeof value === 'number' ||
          typeof value === 'boolean'
        ) {
          if (
            String(value).toLowerCase().includes(searchTerm.toLowerCase()) && keys.includes(key)
          ) {
            return true;
          }
        }
      }
      return false;
    };
    return data.filter((item) => search(item));
  };



  const handleNext = () => {
    if (currentPage < (totalPages - 1)) {
      setCurrentPage(currentPage + 1);
    }

  }

  const handlePrev = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  }

  const setPage = (e) => {
    if (e.target.value > 0 && e.target.value <= totalPages) {
      setPageJump(e.target.value - 1);
    }
  }

  const handleKeyPress = (event) => {
    event.preventDefault();
    setCurrentPage(pageJump);
  }

  return (
    permission !== null?
    <div className="content-body">
      <div className="dashboard-content">
        <div className="top-content">
          <div className="left-side-search">
            <p className='dashboard-title'>Channel Management </p>
            <div className='channel-setup-search'>
            <SearchBar placeholder="Search by channel name" keyPress={false} searchOutput={handleSearch} />
          </div>
          </div>
       
        </div>
        
        <div className='distribution-header'>
        <ChannelPageButton/>
        </div>
      </div>
      <ToastContainer />
      
      {errorbox && (
              <ErrorPopup
                btntext={btntext}
                message={errorMessage}
                closebox={() => errorboxclose()}
                overrides={() => eventdelete()}
              />
            )}
    </div>
    :
    ''
  )
}

export default ChannelSetup;