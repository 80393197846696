import React, { useState } from 'react';

const DropdownCell = ({ row, field, options, updateRow }) => {
  const [isOpen, setIsOpen] = useState(false);


  const displayValue = Array.isArray(row.original[field])
    ? row.original[field].map((item) => item.name).join(', ')
    : row.original[field]?.name || 'Select...';


  const currentValue = row.original[field]?.name || row.original[field] || '';

  const handleSelect = (value) => {

    const selectedOption = options.find((opt) => opt.name === value);
    updateRow(row.index, field, selectedOption || value);
    setIsOpen(false);
  };

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <div
        onClick={() => setIsOpen(!isOpen)}
        style={{
          width: '100%',
          padding: '5px',
          background: '#333',
          color: '#fff',
          border: '1px solid #444',
          borderRadius: '4px',
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <span>{displayValue}</span>
        <span style={{ marginRight: '5px' }}>▼</span>
      </div>
      {isOpen && (
        <div
          style={{
            position: 'absolute',
            top: '100%',
            left: 0,
            right: 0,
            background: '#333',
            border: '1px solid #444',
            borderRadius: '4px',
            marginTop: '2px',
            zIndex: 10,
            maxHeight: '200px',
            overflowY: 'auto',
          }}
        >
          {options?.map((option) => (
            <label
              key={option.id}
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '5px 10px',
                color: '#fff',
                cursor: 'pointer',
                borderBottom: '1px solid #444',
              }}
              onClick={() => handleSelect(option.name)}
            >
              <input
                type="radio"
                value={option.name}
                checked={currentValue === option.name}
                onChange={() => handleSelect(option.name)}
                style={{ marginRight: '8px', width: '10px', height: '10px' }}
              />
              {option.name}
            </label>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownCell;