
import React, { useState } from 'react';
import 'react-time-picker/dist/TimePicker.css';
import TimePicker from 'react-time-picker';

export function TimePickerGrid({ row, column, onRowChange }) {
  let initialTime;
  if (row[column.key] && row[column.key].toUpperCase() !== row[column.key].toLowerCase()) {
    initialTime = "00:00:00";
  } else {
    initialTime = row[column.key] || "00:00:00";
  }

  const [timeValue, setTimeValue] = useState(initialTime);
  const handleRowChange = typeof onRowChange === 'function' ? onRowChange : () => {};

  const handleTimeChange = (newTime) => {
    if (!newTime) {
      setTimeValue("00:00:00");
      const updatedRow = { ...row, [column.key]: "00:00:00" };
      handleRowChange(updatedRow);
      return;
    }

    const [hours, minutes, seconds] = newTime.split(':').map(num => parseInt(num) || 0);
    const formatNumber = (num) => num.toString().padStart(2, '0');
    const validatedTime = `${formatNumber(hours)}:${formatNumber(minutes)}:${formatNumber(seconds)}`;
    
    setTimeValue(validatedTime);
    const updatedRow = { ...row, [column.key]: validatedTime };
    handleRowChange(updatedRow);
  };

  return (
    <TimePicker 
      value={timeValue}
      hourPlaceholder="HH"
      minutePlaceholder="mm"
      secondPlaceholder="ss"
      maxDetail="second"
      onChange={handleTimeChange}
      disableClock={true}
      format="HH:mm:ss"
      clearIcon={null}
      clockIcon={null}
      isOpen={false}
    />
  );
};