import React, { useState, useEffect } from "react";
import "./EditChannelPopup.scss";
import Select from "react-select";
import { useSelector } from "react-redux";
import UploadIcon from '../../assets/svg/Distribution/uil_upload.svg'

const EditChannelPopup = ({
    channel,
    updateChannelData,
    onClose,
    channelTypeList = [],
    initialDistributionType = "Global URL",
    initialAdTagType = "Global URL"
}) => {
    const [selectedChannel, setSelectedChannel] = useState(channel.name || "");
    const [ssaiEnabled, setSsaiEnabled] = useState("Yes");
    const [mediaOption, setMediaOption] = useState("Yes");
    const [distributionActiveTab, setDistributionActiveTab] = useState(initialDistributionType);
    const [uploadSections, setUploadSections] = useState(["Cover art", "Hero partner"]);

    const [selectedTerritories, setSelectedTerritories] = useState([]);
    const state = useSelector((state) => state);
    const Territorys = state?.CountryList?.data?.data || [];
    const [territoryOption, setTerritoryOption] = useState([]);
    const [sections, setSections] = useState([]);


    const addUploadSection = () => {
        if (sections.length === 0) {
            setSections(["Cover art", "Hero partner", "Upload 3"]);
        }
    };

    const mappedTerritoryOptions = Territorys.map((item) => ({
        value: item.iso,
        label: item.niceName
    }));


    const handleChannelChange = (event) => {
        const newChannelName = event.target.value;
        setSelectedChannel(newChannelName);
        updateChannelData(channel.id, "name", newChannelName);
    };

    const handleToggle = (type, value) => {
        if (type === "ssai") setSsaiEnabled(value);
        if (type === "media") setMediaOption(value);
    };

    const customStyles = {
        control: (base, state) => ({
            ...base,
            backgroundColor: "#222", // Dark background
            borderColor: state.isFocused ? "#888" : "#555",
            color: "#fff",
            "&:hover": {
                borderColor: "#999"
            }
        }),
        menu: (base) => ({
            ...base,
            backgroundColor: "#222", // Dropdown background
            color: "#fff"
        }),
        menuList: (base) => ({
            ...base,
            backgroundColor: "#222" // Dark dropdown list
        }),
        option: (base, { isFocused, isSelected }) => ({
            ...base,
            backgroundColor: isSelected ? "#444" : isFocused ? "#555" : "#222",
            color: "#fff",
            "&:hover": {
                backgroundColor: "#666"
            }
        }),
        multiValue: (base) => ({
            ...base,
            backgroundColor: "#555", // Selected items background
            color: "#fff"
        }),
        multiValueLabel: (base) => ({
            ...base,
            color: "#fff"
        }),
        multiValueRemove: (base) => ({
            ...base,
            color: "#fff",
            "&:hover": {
                backgroundColor: "#777",
                color: "#000"
            }
        })
    };
    useEffect(() => {
        if (Territorys.length > 0) {
            let countries = Territorys.map((item) => ({
                territoryId: item.iso,
                territoryName: item.niceName
            }));

            countries.sort((a, b) => a.territoryName.localeCompare(b.territoryName));
            setTerritoryOption(countries);
        }
    }, [Territorys]);


    return (
        <div className="overlay">
            <div className="popup-container">
                <h2 className="popup-title">Edit Channel Details</h2>
                <div className="form-group">
                    <label>Channel Name <span className="required">*</span></label>
                    <select name="channelName" onChange={handleChannelChange} value={selectedChannel} required>
                        <option value="" disabled>Select Channel Name</option>
                        {channelTypeList.map((item, index) => (
                            <option key={index} value={item.type}>
                                {item.type}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="form-group">
                    <div className="input-group">
                        <label>Distribution URL <span className="required">*</span></label>
                        <div className="parent-container">
                            <button className="BtnGlobal">{distributionActiveTab === "Global URL" ? "Global" : "Unique"}</button>
                        </div>
                    </div>
                    <input
                        type="text"
                        value={channel.distributionUrl}
                        onChange={(e) => updateChannelData(channel.id, "distributionUrl", e.target.value)}
                        placeholder="URL ID 000000"
                    />

                </div>
                <div className="form-group">
                    <div className="input-group">
                        <label>Ad Tag URL <span className="required">*</span></label>
                        <div className="parent-container">
                            <button className="BtnGlobal">{distributionActiveTab === "Global URL" ? "Global" : "Unique"}</button>
                        </div>
                    </div>
                    <input
                        type="text"
                        value={channel.ssaiUrl}
                        onChange={(e) => updateChannelData(channel.id, "ssaiUrl", e.target.value)}
                        placeholder="URL ID 000000"
                    />

                </div>
                <div className="form-groupSSAI">
                    <label>SSAI URL <span className="required">*</span></label>
                    <div className="tabContainer">
                        <button
                            className={ssaiEnabled === "Yes" ? "active" : ""}
                            onClick={() => setSsaiEnabled("Yes")}
                        >
                            Yes
                        </button>
                        <button
                            className={ssaiEnabled === "No" ? "active" : ""}
                            onClick={() => setSsaiEnabled("No")}
                        >
                            No
                        </button>
                    </div>
                </div>
                <div className="form-group">
                    <label className="formLabel">Territory <span className="required">*</span></label>
                    <Select
                        isMulti
                        options={mappedTerritoryOptions}
                        value={selectedTerritories}
                        onChange={setSelectedTerritories}
                        className="multiSelectOption"
                        styles={customStyles}
                    />
                </div>
                <div className="form-groupSSAI">
                    <label>
                        Media <span className="required">*</span>
                    </label>

                    <div className="tabContainer">
                        <button
                            className={mediaOption === "yes" ? "active" : ""}
                            onClick={() => setMediaOption("yes")}
                        >
                            Yes
                        </button>
                        <button
                            className={mediaOption === "Overwrite" ? "active" : ""}
                            onClick={() => setMediaOption("Overwrite")}
                        >
                            Overwrite
                        </button>
                    </div>
                </div>
                {mediaOption === "Overwrite" && (
                    <div className="media-upload-section-distribution">
                        <div className="upload-box-distribution">
                            <div className="upload-placeholder-distribution">
                                <span className="upload-icon-distribution">
                                    <img src={UploadIcon} alt="upload" />
                                </span>
                                <p className="upload-label-distribution">Cover art<br />Size: 16 x 14px</p>
                            </div>
                        </div>
                        <div className="upload-box-distribution">
                            <div className="upload-placeholder-distribution">
                                <span className="upload-icon-distribution">
                                    <img src={UploadIcon} alt="upload" />
                                </span>
                                <p className="upload-label-distribution">Hero partner<br />Size: 16 x 14px</p>
                            </div>
                        </div>
                    </div>
                )}
                <div className="form-groupOther-up">
                    <div className="upload-name ">
                        <label className="formLabel-up">
                            Other Media <span className="required-up">*</span>
                        </label>

                        <button className="upload-btn-up" onClick={addUploadSection}>+ Upload</button>
                    </div>
                    {sections.length > 0 && (
                        <div className="media-upload-section-distribution">
                            {sections.map((label, index) => (
                                <div key={index} className="upload-box-distribution">
                                    <div className="upload-placeholder-distribution">
                                        <span className="upload-icon-distribution">
                                            <img src={UploadIcon} alt="upload" />
                                        </span>
                                        <p className="upload-label-distribution">{label}<br />Size: 16 x 14px</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div className="popup-actions">
                    <button className="cancel-btn" onClick={onClose}>Cancel</button>
                    <button className="add-btn">Add</button>
                </div>
            </div>
        </div>
    );
};

export default EditChannelPopup;
