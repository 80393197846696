
import React, { useState, useEffect, useMemo, useCallback, Suspense } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import { MetaGridAction } from '../../Redux/slices';
import { GET_METADATA, POST_METADATA_BULK, Metadataupdate_URL } from '../../service/API_URL';
import { getInstantData, postInstantData, deleteInstantData } from '../../api/apiMethod';
import { permissionDetails } from '../../utility/localStoageData';
import { ContentGroup } from '../../service/Constant';
import backicon from '../../assets/svg/metaEdit/arrow-left.svg';
import styles from './editable-grid.module.scss';
import * as XLSX from 'xlsx';
import SearchBar from './SearchBar';
import AddRowButton from './AddRowButton';
import DeleteRowButton from './DeleteRowButton';
import ExportButton from './ExportButton';
import UpdateButton from './UpdateButton';
import LoadingSpinner from './LoadingSpinner';
import NoDataMessage from './NoDataMessage';
import { getColumns } from './columns';

const EditableTable = React.lazy(() => import('../../component/EditableTable'));
const ReactTable = React.lazy(() => import('@tanstack/react-table').then(module => ({
  default: module.useReactTable
})));

function EditableGrid({ data }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locationState = useLocation();
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [displayedRows, setDisplayedRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [locationData, setLocationData] = useState([]);
  const [permission, setPermission] = useState(false);
  const [selectedRows, setSelectedRows] = useState(new Set());
  const [page, setPage] = useState(0);
  const rowsPerPage = 50;

  const { AudioLanguageList, MaturityList, CountryList } = useSelector((state) => ({
    AudioLanguageList: state.AudioLanguageList?.data,
    MaturityList: state.MaturityList?.data,
    CountryList: state.CountryList?.data?.data,
  }));

  const { GenreList, ThemesList, CategoriesList } = useSelector((state) => ({
    GenreList: state.GenreList,
    ThemesList: state.ThemesList,
    CategoriesList: state.CategoriesList,
  }));

  useEffect(() => {
    setPermission(permissionDetails().includes('ASSET_MANAGEMENT_EDIT'));
    if (locationState?.state?.name) {
      setLocationData({ state: locationState.state });
      getMetaData(locationState.state.id);
    } else {
      getMetaData(32);
    }
  }, [locationState]);

  useEffect(() => {
    const filteredData = data?.filter(row => row.partnerId === locationData.state?.id) || data || [];
    setRows(filteredData);
    setFilteredRows(filteredData);
    setDisplayedRows(filteredData.slice(0, rowsPerPage));
  }, [data, locationData]);

  useEffect(() => {
    if (searchText.length >= 2) {
      const filtered = rows.filter(row =>
        (row.programId && row.programId.toString().toLowerCase().includes(searchText.toLowerCase())) ||
        (row.title && row.title.toLowerCase().includes(searchText.toLowerCase()))
      );
      setFilteredRows(filtered);
      setDisplayedRows(filtered.slice(0, rowsPerPage));
      setPage(0);
    } else {
      setFilteredRows(rows);
      setDisplayedRows(rows.slice(0, rowsPerPage));
    }
  }, [searchText, rows]);

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && !loading && displayedRows.length < filteredRows.length) {
      loadMoreRows();
    }
  };

  const loadMoreRows = () => {
    const nextPage = page + 1;
    const newRows = filteredRows.slice(0, (nextPage + 1) * rowsPerPage);
    setDisplayedRows(newRows);
    setPage(nextPage);
  };

  const handleRowSelection = useCallback((rowId) => {
    setSelectedRows((prev) => {
      const newSelection = new Set(prev);
      if (newSelection.has(rowId)) newSelection.delete(rowId);
      else newSelection.add(rowId);
      return newSelection;
    });
  }, []);

  const getMetaData = async (partnerId) => {
    setLoading(true);
    const URL = `${GET_METADATA}?partnerId=${partnerId}`;
    try {
      const response = await getInstantData(URL);
      const channelData = response?.data || [];
      setRows(channelData);
      setFilteredRows(channelData);
      setDisplayedRows(channelData.slice(0, rowsPerPage));
      dispatch(MetaGridAction(channelData));
    } catch (error) {
      toast.error('Failed to load metadata');
    } finally {
      setLoading(false);
    }
  };

  const updateMetaData = async () => {
    setLoading(true);
    const URL = `${POST_METADATA_BULK}`;
    const updatedData = updateIds();
    try {
      await postInstantData(URL, { movies: updatedData });
      getMetaData(locationData?.state?.id);
      toast.success('Metadata updated successfully');
    } catch (error) {
      toast.error('Something went wrong');
    } finally {
      setLoading(false);
    }
  };

  const addMetaData = () => {
    const emptyRow = {
      programId: '',
      title: '',
      duration: '',
      shortSynopsis: '',
      actors: '',
      directors: '',
      releaseDate: '',
      originalLanguage: '',
      origin: '',
      genres: [],
      themes: [],
      categories: [],
      ratings: '',
      ratingsDescriptor: '',
      territories: [],
      startDate: '',
      endDate: '',
      audioLanguages: [],
      contentGroup: [],
      partnerId: locationData?.state?.id,
    };
    setRows([emptyRow, ...rows]);
    setFilteredRows([emptyRow, ...rows]);
    setDisplayedRows([emptyRow, ...displayedRows]);
  };

  const removeMetaData = async () => {
    if (!selectedRow?.id) {
      setRows(rows.slice(1));
      setFilteredRows(filteredRows.slice(1));
      setDisplayedRows(displayedRows.slice(1));
      return;
    }
    setLoading(true);
    const URL = `${Metadataupdate_URL}/${selectedRow.id}`;
    try {
      await deleteInstantData(URL);
      const updatedRows = rows.filter((data) => data?.id !== selectedRow?.id);
      setRows(updatedRows);
      setFilteredRows(updatedRows);
      setDisplayedRows(updatedRows.slice(0, (page + 1) * rowsPerPage));
      toast.success('Metadata removed successfully');
    } catch (error) {
      toast.error('Something went wrong');
    } finally {
      setLoading(false);
    }
  };

  const exportToExcel = () => {
    const dataWithHeaders = mapDataWithHeaders(filteredRows);
    const worksheet = XLSX.utils.json_to_sheet(dataWithHeaders);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Metadata');
    XLSX.writeFile(workbook, 'metadata_export.xlsx');
  };

  const updateRow = (index, key, value) => {
    setRows((prevRows) =>
      prevRows.map((row, i) => (i === index ? { ...row, [key]: value } : row))
    );
    setDisplayedRows((prevDisplayed) =>
      prevDisplayed.map((row, i) => (i === index ? { ...row, [key]: value } : row))
    );
  };

  const updateIds = () => {
    if (!rows) return [];
    return rows.map((item) => ({
      ...item,
      partnerId: parseInt(locationData?.state?.id),
      themeIds: item.themes?.map((res) => res.id) || [],
      categoryIds: item.categories?.map((res) => res.id) || [],
      genreIds: item.genres?.map((res) => res.id) || [],
      audioLanguageIds: item.audioLanguages?.map((res) => res.id) || [],
      ratingIds: MaturityList?.filter((res) => res?.name === item.ratings)?.[0]?.id
        ? [MaturityList?.filter((res) => res?.name === item.ratings)?.[0]?.id]
        : item.ratings?.map((res) => res?.id) || [],
      territoryIds: item.territories?.map((res) => res.id) || [],
      originalLanguageId: item.originalLanguage?.id ? Number.parseInt(item.originalLanguage.id) : null,
      origin: item?.origin?.name || item?.origin,
      contentGroup: item.contentGroup?.length ? item.contentGroup : null,
    }));
  };

  const mapDataWithHeaders = (dataRows) => {
    return dataRows?.map((item) => ({
      'Prog. ID': item?.programId,
      Title: item?.title,
      Duration: item?.duration,
      'Short Synopsis': item?.shortSynopsis,
      Actors: item?.actors,
      Directors: item?.directors,
      'Release Date': item?.releaseDate,
      'Original Language': item?.originalLanguage?.name || item?.originalLanguage,
      Origin: item?.origin?.name || item?.origin,
      Genre: item?.genres?.map((data) => data?.name).join(', '),
      Themes: item?.themes?.map((data) => data?.name).join(', '),
      Categories: item?.categories?.map((data) => data?.name).join(', '),
      Rating: item?.ratings?.name || item?.ratings,
      'Ratings Descriptor': item?.ratingsDescriptor,
      Territory: item?.territories?.map((data) => data?.name).join(', '),
      'Start Date': item?.startDate,
      'End Date': item?.endDate,
      'Audio Language': item?.audioLanguages?.map((data) => data?.name).join(', '),
      'Content Group': item?.contentGroup?.join(', '),
    }));
  };

  const columns = useMemo(
    () => getColumns({
      AudioLanguageList,
      CountryList,
      GenreList,
      ThemesList,
      CategoriesList,
      MaturityList,
      ContentGroup,
      updateRow,
    }),
    [AudioLanguageList, CountryList, GenreList, ThemesList, CategoriesList, MaturityList]
  );

  return (
    <div className="content-body" style={{ position: 'relative', overflowX: 'hidden' }}>
      <div className="dashboard-content">
        <div className="top-content">
          <h3>
            <a onClick={() => navigate(-1)}>
              <img src={backicon} alt="Back Icon" />
            </a>
            <span style={{ color: '#999999', marginLeft: 20 }}>
              Metafile for {locationData?.state?.name}
            </span>
          </h3>
          {permission && (
            <div className="right-side flex">
              <SearchBar searchText={searchText} setSearchText={setSearchText} />
              <DeleteRowButton onClick={removeMetaData} />
              <AddRowButton onClick={addMetaData} />
              <ExportButton onClick={exportToExcel} />
              <UpdateButton onClick={updateMetaData} />
            </div>
          )}
        </div>

        {loading ? (
          <LoadingSpinner />
        ) : filteredRows.length === 0 ? (
          <NoDataMessage />
        ) : (
          <div className={styles.bulkGrid} onScroll={handleScroll} style={{ maxHeight: '80vh', overflowY: 'auto' }}>
            <div className={styles.scrollContent}>
              <Suspense fallback={<LoadingSpinner />}>
                <EditableTable data={displayedRows} columns={columns} />
              </Suspense>
            </div>
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
}

export default EditableGrid;